import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import SearchContext from '../contexts/SearchContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'

import { Container } from './Grid'
import { ColoredLink } from './Typography'

const DetailsContainer = styled.div`
  background-color: #fff;
  padding: .6rem 0;
  box-shadow: 0px -2px 15px 11px rgb(0 0 0 / 20%);
  position: relative;
  text-align: center;
`

const DetailsDescription = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin: .5rem;
  }
`

const StyledColoredLink = styled(ColoredLink)`
  margin-left: .4rem;
  color: ${({ toggle, theme }) => toggle ? '#000' : theme.primaryColor};
  text-decoration: ${({ toggle }) => toggle ? 'none' : 'underline'};
  font-weight: ${({ toggle }) => toggle ? 'bold' : 'normal'};
`

const ToggleDetails = styled.div`
  height: ${({ toggle }) => toggle ? 'auto' : '0'};
  padding: ${({ toggle }) => toggle ? '0.5rem 0' : '0'};
  opacity: ${({ toggle }) => toggle ? '1' : '0'};
  width: 100%;
`

const CloseDetails = styled(ColoredLink)`
  display: ${({ toggle }) => toggle ? 'block' : 'none'};
  margin-top: 1rem;
`

const AllCamerasContainer = styled.div`
  width: 100%;
  display: ${({ toggle }) => toggle ? 'flex' : 'none'};
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin: 0.3rem;
  }
`

const Camera = styled.div`
  width: fit-content;
  font-family: Blinker;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
  text-align: center;
  border: 2px solid #999999;
  color: ${({ selected }) => selected ? '#fff' : '#999999'};
  background-color: ${({ selected }) => selected ? '#999999' : 'transparent'};
  padding: .1rem .7rem;
  border-radius: 5px;
`

const Cameras = ({ request, toggle, loading, cameras }) => {
  if (loading)
    return (
      <FontAwesomeIcon
        icon={loading ? faSpinner : faDownload}
        spin={loading}
        style={{ marginRight: '5px' }}
      />
    )

  return (
    <AllCamerasContainer toggle={toggle}>
      {
        cameras
          .filter(({ id }) => request.cameras.includes(id.toString()))
          .map((camera, i) => (
            <Camera
              selected={true}
              key={`${camera.name}-${i}`}
            >
              {camera.name}
            </Camera>
          ))
      }
    </AllCamerasContainer>
  )
}

const Details = ({ request, loading }) => {
  const [
    { tags, carMakes, carModels, carColors, cameras },
    { fetchCarModels, fetchCameras }
  ] = useContext(SearchContext)
  const [toggle, setToggle] = useState(false)
  const [camerasLoading, setCamerasLoading] = useState(true)
  const requestCameras = request.cameras
  const since = new Date(Number(request.since))
  const until = new Date(Number(request.until))

  useEffect(() => {
    fetchCarModels(request.make)
    fetchCameras().then(() => setCamerasLoading(false))
  }, [ request ])

  return (
    <DetailsContainer>
      <Container>
        <DetailsDescription>
          <div>
            Caméras {
              loading ? <FontAwesomeIcon icon={loading ? faSpinner : faDownload} spin={loading} style={{ marginRight: '5px' }} /> : requestCameras.length
            }
            <StyledColoredLink toggle={toggle} onClick={() => setToggle(!toggle)}>Détails</StyledColoredLink>
          </div>
          <div>-</div>
          <div>
            {
              request.tag
              ? tags.filter(tag => tag.name === request.tag)[0].displayName
              : 'VPI'
            }
            {' '}
            {
              request.tag === 'car' && carMakes.filter(brand => brand.name === request.make)[0] &&
              carMakes.filter(brand => brand.name === request.make)[0].displayName
            }
            {' '}
            {
              request.tag === 'car' && carModels.filter(model => model.name === request.model)[0] &&
              carModels.filter(model => model.name === request.model)[0].displayName
            }
            {' '}
            {
              request.tag === 'car' && carColors.filter(color => color.name === request.color)[0] &&
              carColors.filter(color => color.name === request.color)[0].displayName
            }
          </div>
          <div>-</div>
          <div>
            {`
              Du ${strftime('%d/%m/%Y', since)} ${strftime('%H:%M', since)} au ${strftime('%d/%m/%Y', until)} ${strftime('%H:%M', until)}
            `}
          </div>
        </DetailsDescription>
      </Container>
      <ToggleDetails toggle={toggle}>
        <Container>
          {
            loading
              ? <FontAwesomeIcon icon={loading ? faSpinner : faDownload} spin={loading} style={{ marginRight: '5px' }} />
              : (
                <Cameras
                  toggle={toggle}
                  request={request}
                  loading={camerasLoading}
                  cameras={cameras}
                />
              )
            }
        </Container>
        <CloseDetails
          onClick={() => setToggle(false)}
          color='#F61A1A'
          toggle={toggle}
        >
          Fermer le volet
        </CloseDetails>
      </ToggleDetails>
    </DetailsContainer>
  )
}

export default Details
