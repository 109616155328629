import React from 'react'
import styled from 'styled-components'

import SRModal from 'styled-react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { H3 } from './Typography'

const StyledModal = SRModal.styled`
  position: relative;
  width: 80%;
  max-width: 960px;
  margin: 2.5% auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  right: -20px;
  top: -20px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`

const Container = styled.div``

export const Modal = ({ onCloseClick, children, ...props }) => (
  <StyledModal {...props} >
    <CloseButton onClick={onCloseClick}>
      <FontAwesomeIcon icon={faTimes} />
    </CloseButton>
    <Container>
      {children}
    </Container>
  </StyledModal>
)

export const ModalTitle = props => (
  <H3 {...props} />
)

export const ModalBody = props => (
  <div {...props} />
)
