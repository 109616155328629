import { hot } from 'react-hot-loader/root'
import React from 'react'
import 'normalize.css'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { ThemeProvider, GlobalStyle, theme } from './Theme'
import { AuthProvider } from './contexts/AuthContext'

import { Footer } from './components'

import Navigation from './modules/Navigation'
import ProtectedRoute from './modules/ProtectedRoute'

import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'

// import Header from './components/Header'
// import Footer from './components/Footer'
import Search from './pages/Search'
import Detections from './pages/Detections'
import VPI from './pages/VPI'
import Plates from './pages/Plates'
import Videos from './pages/Videos'
import Cameras from './pages/Cameras'
import Watch from './pages/Watch'
import Intercept from './pages/Intercept'
import Account from './pages/Account'
import Alert from './pages/Alert'

import { ModalProvider } from 'styled-react-modal'
import { SearchProvider } from './contexts/SearchContext'

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ModalProvider>
            <Navigation />
            <div style={{ flex: '1 0 auto', position: 'relative' }}>
              <SearchProvider>
                <Switch>
                  {/*
                    <Route exact path="/watch" component={Watch} />
                    <Route exact path="/intercept" component={Intercept} />
                    <Route exact path="/videos" component={Videos} />
                  */}
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path="/reset-password" component={ResetPassword} />
                  <ProtectedRoute path="/account/:resource" component={Account} />
                  <ProtectedRoute exact path="/logout" component={Logout} />
                  <ProtectedRoute exact path="/cameras" component={Cameras} />
                  <ProtectedRoute exact path="/search" component={Search} />
                  <ProtectedRoute exact path="/search/:id" component={Detections} />
                  <ProtectedRoute exact path="/alert" component={Alert} />
                  <ProtectedRoute exact path="/vpi" component={VPI} />
                  <ProtectedRoute path="/vpi/:id" component={Plates} />
                  <Route path="*">
                    <Redirect to="/search" />
                  </Route>
                </Switch>
              </SearchProvider>
            </div>
            <Footer />
          </ModalProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default hot(App)
