import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import strftime from 'strftime'
import axios from 'axios'

import * as services from '../services'

import SearchContext from '../contexts/SearchContext'
import { SearchSection } from '../components/SearchSection'
import { SmallButton, BigButton } from '../components/Button'

import { Container, CenterContainer } from '../components/Grid'
import { H1, Error, Link } from '../components/Typography'
import { EditIcon, SearchIcon, AddCircleFilledIcon } from '../components/design-system'
import { TextInput } from '../components/Input'
import HistoryTable from '../components/HistoryTable'

import SearchDateSection from '../components/SearchDateSection'
import SearchCamerasSection from '../components/SearchCamerasSection'
import PlateSearch from '../components/PlateSearch'

const StyledH1 = styled(H1)`
  color: #000;
  text-transform: none;
  margin-bottom: 0;
`

/*
** Perimeter Section
*/

const PerimeterSearchSection = styled(SearchSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) => displayCameras ? '0' : '2rem'} !important;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const HistorySearchSection = styled(SearchSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

// const fetchRequests = () => axios.get('/api/anpr/requests').then(({ data }) => data)
// const postRequest = params => axios.post('/api/anpr/requests', params).then(({ data }) => data)
const cancelRequest = id => axios.put(`/api/anpr/requests/${id}/cancel`).then(({ data }) => data)

const VPI = ({ history }) => {
  const [
    { cameras },
    { fetchCameras }
  ] = useContext(SearchContext)

  const [newPlate, setNewPlate] = useState('')
  const [plates, setPlates] = useState([])

  const [researchName, setResearchName] = useState('')
  const [plateError, setPlateError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [requests, setRequests] = useState([])
  const [displayCameras, setDisplayCameras] = useState(false)
  const [allSectors, setAllSectors] = useState(null)
  const [form, setForm] = useState({
    cameras: [],
    plates: [],
    since: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000 - 1 * 60 * 60 * 1000), // -1year -1h in ms
    until: new Date()
  })

  useEffect(() => {
    fetchCameras()
      .then(({ cameras }) => setForm({ ...form, cameras: cameras.map(({ id }) => id) }))
    services.fetchAllVPISearch()
      .then(({ requests }) => setRequests(requests))
    services.fetchAllSectors({})
      .then(({ requests }) => {
        setAllSectors(requests)
      })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    setSubmitError(null)

    services.addVAPISearch({
      ...form,
      name: researchName,
      cameras: form.cameras.join(','),
      plates: plates.map(p => p.toUpperCase()).join(','),
      since: form.since.getTime(),
      until: form.until.getTime(),
    }).then(({ request }) => {
      history.push(`/vpi/${request.id}`)
    })
  }

  // const handleCancel = (e, id) => {
  //   e.preventDefault()
  //   cancelRequest(id).then(_ => fetchAllVPISearch().then(({ requests }) => setRequests(requests)))
  // }

  const handleChange = value => {
    const regexTest = /(^[a-zA-Z0-9\*]+$)|^$/g.test(value);

    if (regexTest) setNewPlate(value.toUpperCase())
  }

  const toggleDisplayCameras = () =>
    setDisplayCameras(!displayCameras)

  const setDate = (name, value) =>
    setForm({ ...form, [name]: value })

  const addPlate = (event) => {
    event.preventDefault()

    if (newPlate === '') {
      setPlateError('Veuillez entrer un numéro de plaque d\'immatriculation.')
      return
    }
    if (plates.filter(plate => plate === newPlate).length != 0) {
      setPlateError('Numéro de plaque d\'immatriculation déjà entré.')
      return
    }
    setPlates([...plates, newPlate])
    setNewPlate('')
    setPlateError(null)
  }

  const handleSetResearchName = value => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value);

    if (regexTest) setResearchName(value)
  }

  /*const tableHeader = [
    {
      name: 'Date',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Nom',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Périmètre',
      sort: list => list.slice().sort((a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      }),
    },
    { name: 'Type' },
    // { name: 'Recherche' },
    { name: 'Status' },
    { name: 'Action' },
  ]*/

  const tableHeader = [
    {
      name: 'Date',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Nom',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Début',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Fin',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Périmètre',
      sort: list => list.slice().sort((a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      }),
    },
    {
      name: 'Type',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Status',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    { name: 'Résultats' }
  ]

  return (
    <>
      <StyledH1>Nouvelle recherche VPI</StyledH1>
      <PerimeterSearchSection
        displayCameras={displayCameras}
        title='Périmètre'
        icon='/images/camera.png'
        description={<>
          <strong>Filtrer les caméras</strong> concernées par la recherche.
          Au besoin, regroupez plusieurs caméras par secteurs via le <Link to='/cameras'>menu Caméras</Link>.
        </>}
      >
        <SmallButton type='button' onClick={toggleDisplayCameras} primary>
          {displayCameras ? 'Masquer les caméras' : 'Afficher les caméras'}
        </SmallButton>
      </PerimeterSearchSection>
      <SearchCamerasSection
        selectedCameras={form.cameras}
        camerasList={cameras}
        sectors={allSectors}
        selectedCameras={form.cameras}
        setSelectedCameras={v => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
      />
      <SearchSection
        title='Plaques'
        icon='/images/filters.svg'
        description={<>
          <strong>Indiquez les plaques</strong> à rechercher. <strong>Si vous ne disposez que d’une partie de la plaque</strong>,
          veillez à positionner les numéros dont vous disposez à leur place exacte et inscrivez
          « * » à la place des autres. Par exemple pour une plaque française à 7 caractères,
          si vous ne disposez que du premier caractère « A » et des quatrième et cinquième caractères « 1 »
          et « 2 », renseignez « A**12** ».
        </>}
      >
        <PlateSearch
          plates={plates}
          setPlates={setPlates}
          addPlate={addPlate}
          newPlate={newPlate}
          handleChange={handleChange}
          plateError={plateError}
        />
      </SearchSection>
      <SearchSection
        title='Période'
        icon='/images/calendar.png'
        description={<><strong>Définissez la période</strong> de recherche.</>}
      >
        <SearchDateSection until={form.until} since={form.since} setDate={setDate} />
      </SearchSection>
      <SearchSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <TextInput
              label='Nommer la recherche'
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            {
              submitError && <Error>{submitError}</Error>
            }
            <BigButton onClick={handleSubmit} type='submit' leftIcon={<SearchIcon />}>
              Lancer la recherche
            </BigButton>
          </form>
        </NameInputContainer>
      </SearchSection>
      <HistorySearchSection title='Recherches en cours' icon='/images/search.svg'>
        <HistoryTable
          header={tableHeader}
          type='VPI'
          body={
            requests.slice().map((e, i) => {
              if (i >= 10) return null
              return ({
                ...e,
                type: 'VPI',
                search: e.plates.split(',').join(', '),
                date: strftime('%d/%m/%Y', new Date(Number(e.at))),
                hour: strftime('%H:%M', new Date(Number(e.at)))
              })
            })
          }
        />
        <SmallButton onClick={() => history.push('/account/history')}>Voir l'historique complet</SmallButton>
      </HistorySearchSection>
    </>
  )
}

export default VPI
