import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faFastBackward, faSpinner } from '@fortawesome/free-solid-svg-icons'

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Screen = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

const VideoPlayer = styled.video`
  display: block;
  width: 100%;

  ${({ hidden }) => hidden && `
    display: none;
  `}
`

const CanvasPlayer = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const Poster = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const Controls = styled.div`
  background: #000;
  background: rgba(0, 0, 0, .8);

  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

const ControlButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  margin: 0 5px;
  padding: 8px;
  float: left;
`

const RangeButton = styled.button`
  float: right;
  width: 75%;
  margin: 12px 10px 0 10px;
`

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -30px;
  width: 60px;
  height: 40px;
  line-height: 40px;
  color: white;
  background: #000;
  border-radius: 4px;
  text-align: center;
`

function delay(t, v) {
  return new Promise(function(resolve) {
      setTimeout(resolve.bind(null, v), t)
  });
}

var hasFirstDetection = false

export default ({
  poster = null,
  preload = "none",
  videos = [],
  showControls = true,
  playOnHover = false,
  pauseAtFirstDetection = false,
  onCanPlay = () => null
}) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  const [paused, setPaused] = useState(true)
  const [stopped, setStopped] = useState(true)
  const [waiting, setWaiting] = useState(false)

  const players = useRef([])
  const canvas  = useRef(null)

  const detectionByTime = (index, time) =>
    videos[index].detections.find(d => Number(d.time.toFixed(2)) >= (time - 0.005) && Number(d.time.toFixed(2)) <= (time + 0.005))

  const clearDetection = () => {
    const _canvas = canvas.current

    if (!_canvas) return

    const _context = _canvas.getContext('2d')

    _context.clearRect(0, 0, _canvas.width, _canvas.height)
  }

  const drawDetection = (index, _detection) => {
    const _player = players.current[index]
    const _canvas = canvas.current

    if (!_player || !_canvas) return

    const _context = _canvas.getContext('2d')
    const _scale = window.devicePixelRatio

    _canvas.width = _player.offsetWidth * _scale
    _canvas.height = _player.offsetHeight * _scale

    const videoWidth = _player.videoWidth
    const videoHeight = _player.videoHeight
    const canvasWidth = _canvas.width / _scale
    const canvasHeight = _canvas.height / _scale

    const boxWidth = (canvasWidth / videoWidth) * _detection.width
    const boxHeight = (canvasHeight / videoHeight) * _detection.height
    const boxX = (canvasWidth / videoWidth) * _detection.x
    const boxY =(canvasHeight / videoHeight) * _detection.y

    _context.scale(_scale, _scale)
    _context.clearRect(0, 0, _canvas.width, _canvas.height)

    _context.lineWidth = 1
    _context.strokeStyle = 'red'
    _context.strokeRect(boxX, boxY, boxWidth, boxHeight)
    _context.stroke()
  }

  const lastVideo = videos[videos.length - 1]

  const play = (index = 0) => {
    setCurrentVideoIndex(index)

    if (stopped) {
      reset()
      setStopped(false)
    }

    const _player = players.current[index]

    if (!_player) return

    const _frameInfoCallback = (_, metadata) => {
      const _detection = detectionByTime(index, metadata.mediaTime)

      if (index === (videos.length - 1) &&
          lastVideo.lastDetectionAt <= metadata.mediaTime) {
            hasFirstDetection = false
            return stop(index)
          }

      if (_detection) {
        drawDetection(index, _detection)
        if (pauseAtFirstDetection && !(() => hasFirstDetection)()) {
          hasFirstDetection = true
          pause(index)
        }
      } else clearDetection()

      _player.requestVideoFrameCallback(_frameInfoCallback)
    }

    _player.requestVideoFrameCallback(_frameInfoCallback)
    _player.play().catch(_ => setWaiting(false))

    setPaused(false)
  }

  const pause = (index) => {
    const _player = players.current[index]

    if (!_player) return

    _player.pause()
    setPaused(true)
  }

  const stop = (index) => {
    pause(index)
    setStopped(true)
  }

  const reset = () => {
    const _canvas = canvas.current

    if (!_canvas) return

    const _context = _canvas.getContext('2d')

    stop(currentVideoIndex)
    setCurrentVideoIndex(0)
    hasFirstDetection = false

    _context.clearRect(0, 0, _canvas.width, _canvas.height)
    players.current.forEach((_player, index) => _player.currentTime = !index ? videos[index].firstDetectionAt : 0)
  }

  const oneCanPlay = (index = currentVideoIndex) => {
    setWaiting(false)
    onCanPlay()
  }

  const oneEnded = (index = currentVideoIndex) => {
    if (videos[index+1]) play(index+1)
  }

  const renderControls = () =>
    <Controls>
      {paused &&
        <ControlButton onClick={() => play(currentVideoIndex)}>
          <FontAwesomeIcon icon={faPlay} />
        </ControlButton>
      }
      {!paused &&
        <ControlButton onClick={() => pause(currentVideoIndex)}>
          <FontAwesomeIcon icon={faPause} />
        </ControlButton>
      }
      <ControlButton onClick={() => reset()}>
        <FontAwesomeIcon icon={faFastBackward} />
      </ControlButton>
    </Controls>

  const renderLoader = () =>
    <Loader>
      <FontAwesomeIcon icon={faSpinner} spin />
    </Loader>

  const renderPoster = () =>
    <Poster src={poster} onLoad={oneCanPlay} />

  return (
    <Player
      onMouseEnter={playOnHover ? () => play(0) : null}
      onMouseLeave={playOnHover ? () => stop(currentVideoIndex) : null }
    >
      <Screen>
        {videos.map((video, i) =>
          <VideoPlayer
            ref={el => players.current[i] = el}
            key={`videos-${i}`}
            controls={false}
            name="media"
            preload={preload === 'none' && !stopped ? 'auto' : preload} // preload nexts if playing
            muted="muted"
            hidden={currentVideoIndex != i}
            onCanPlay={() => oneCanPlay(i)}
            onEnded={() => oneEnded(i)}
            onWaiting={() => setWaiting(true)}
          >
            <source
              src={`${window.location.protocol}//${window.location.hostname}/media/${video.filename}#t=${(!i && video.firstDetectionAt) || 0}`}
              type="video/mp4"
            />
          </VideoPlayer>
        )}
        <CanvasPlayer ref={canvas} />
        {!!poster && ((playOnHover && stopped) || !playOnHover) && renderPoster()}
        {waiting && renderLoader()}
      </Screen>
      {showControls && renderControls()}
    </Player>
  )
}
