import axios from 'axios'

export const fetchAllVPISearch = () =>
  axios
    .get(`/api/anpr/requests`)
    .then(({ data }) => data)

export const addVAPISearch = params =>
  axios
    .post(`/api/anpr/requests`, params)
    .then(({ data }) => data)
