import React, { useState } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSpinner,
  faLowVision
} from '@fortawesome/free-solid-svg-icons'
import { PoliceBadgeIcon } from './design-system/Icons'
import SequencePlayer from './SequencePlayer'

const StyledContainer = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

const Rating = styled.div`
  background-color: ${({ confidence }) => {
    if (confidence === 3) return '#92DE42'
    if (confidence === 2) return '#FF9F00'
    return '#F61A1A'
  }};
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 0 0 5px 0;
  display: flex;
  align-item: center;
  padding: .4rem;
  column-gap: .3rem;
  height: 10px;

  & > * {
    height: 8px;
    width: auto !important;
  }
`

const Id = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 0 0 0 5px;
  display: flex;
  align-item: center;
  padding: 0 .4rem;
  column-gap: .3rem;
  font-size: .8rem;
  min-height: 22px;

  &:first-child {
    align-self: center;
  }
`

const StyledSequence = styled.div`
  position: relative;
  background: #000;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 200px;
`

const SequenceInfos = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  text-align: right;

  ${props => props.top && 'top: 0'}
  ${props => props.bottom && 'bottom: 0'}
`

const SequenceLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${SequenceInfos} {
    display: none;
  }
`

const SequenceInfo = styled.li`
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  margin: 2px;
  padding: 2px 5px;
  display: inline-block;
  background: #000;
  background: rgba(0,0,0,.8);
  color: #fff;
  border-radius: 4px;

  ${props => props.left && `
    float: left;
    font-size: 10px;
    line-height: 20px;
  `}
`

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  background: #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Infos = styled.div`
  display: flex;
`

const Actions = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
`

const Action = styled.button`
  float: left;
  height: auto;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline:0;
  }
`

const Description = styled.div`
  width: 50%;
  float: right;
  font-size: .8rem;
  padding: .3rem 0;
  text-align: right;
  display: inline-grid;
`

const SequenceDescription = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Loader = () =>
  <StyledLoader>
    <FontAwesomeIcon icon={faSpinner} spin />
  </StyledLoader>

export default ({
  cameraId,
  trackId,
  configId,
  configName,
  startAt,
  videos,
  at,
  poster,
  confidence,
  showControls = true,
  playOnHover = false,
  favorite:_favorite = false,
  hidden:_hidden = false,
  onClick = () => {}
}) => {
  const [loading, setLoading] = useState(true)
  const [favorite, setFavorite] = useState(_favorite)
  const [hidden, setHidden] = useState(_hidden)

  const getConfidenceRating = confidence => {
    if (confidence >= 66.66) return 3
    if (confidence >= 33.33 && confidence < 66.66) return 2
    if (confidence < 33.33) return 1
  }

  const onAddToFavorite = e => {
    e.preventDefault()
    e.stopPropagation()

    setHidden(false)
    setFavorite(true)
  }

  const onHide = e => {
    e.preventDefault()
    e.stopPropagation()

    setHidden(true)
    setFavorite(false)
  }

  return (
    <StyledContainer onClick={() => onClick(configId, startAt, videos)}>
      <StyledSequence>
        {/* <SequenceLink href="#" onClick={() => onClick({ cameraId, startAt, videos })}> */}
          <SequencePlayer
            poster={poster+`?width=720`}
            videos={videos}
            showControls={showControls}
            playOnHover={playOnHover}
            onCanPlay={() => setLoading(false)}
          />
          {/* {
            <SequenceInfos top>
              {confidence >= 25 &&
                <SequenceInfo left>
                  <FontAwesomeIcon icon={faStar} />
                  {confidence >= 50 && <FontAwesomeIcon icon={faStar} />}
                  {confidence >= 75 && <FontAwesomeIcon icon={faStar} />}
                </SequenceInfo>
              }
              <SequenceInfo>Camera {cameraId}</SequenceInfo>
            <SequenceInfo>{strftime("%d/%m/%Y %H:%M:%S", new Date(startAt))}</SequenceInfo>
            </SequenceInfos>
          } */}
          {/* {!loading &&
            <SequenceInfos bottom>
              <SequenceInfo left>#{trackId}</SequenceInfo>
            </SequenceInfos>
          } */}
        {/*</SequenceLink>*/}
        {loading && <Loader />}
      </StyledSequence>
      <Rating confidence={getConfidenceRating(confidence)}>
        {
          Array(getConfidenceRating(confidence)).fill(undefined).map((e, i) => (
            <FontAwesomeIcon key={`star-${trackId}-${i}`} icon={faStar} />
          ))
        }
      </Rating>
      <Id><div>#{trackId}</div></Id>
      <Infos>
        <Actions>
          <Action onClick={onAddToFavorite}>
            <PoliceBadgeIcon color={favorite ? '#92DE42' : '#000'} />
          </Action>
          <Action onClick={onHide}>
            <FontAwesomeIcon icon={faLowVision} color={hidden ? '#F61A1A' : '#000'} />
          </Action>
        </Actions>
        <Description>
          <SequenceDescription>
            {`Caméra ${configName} #${cameraId || configId}`}
          </SequenceDescription>
          <div>{strftime("%d/%m/%Y • %H:%M:%S", new Date(Number(at)))}</div>
        </Description>
      </Infos>
    </StyledContainer>
  )
}
