import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { getAssetsRootURL } from '../../../utils'

import AuthContext from '../contexts/AuthContext'
import * as services from '../services'

import {
  Section,
  H1,
  H2,
  Form,
  FormGroup,
  TextInput,
  Button,
  Link,
  SpinnerAnimatedIcon,
  LoginIcon,
  CheckCircleIcon
} from '../components'

const RegisterContainer = styled(Section)`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${Form} {
    width: 21.875rem;
    margin: 0 auto;
  }

  ${Link} {
    margin-top: .938rem;
  }

  .login-button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const StyledH1 = styled(H1)`
  letter-spacing: .563rem;
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 2rem 0;
  color: white;
  font-weight: normal;
`

const StyledH2 = styled(H2)`
  color: white;
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 5rem;
  height: 5rem;
  color: #92DE42;
  margin: 1rem auto;
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const FormGroupCaptcha = styled(FormGroup)`
  justify-content: center;
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default ({ location }) => {
  const { from } = location.state || { from: { pathname: '/' } }
  const [{ authenticated }] = useContext(AuthContext)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(null)
  const [onRequest, setOnRequest] = useState(false)
  const [created, setCreated] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(firstname.length <= 0) ? <>Prénom requis<br /></> : null}
          {(lastname.length <= 0) ? <>Nom requis<br /></> : null}
          {(email.length <= 0) ? <>Email requis<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>Email format incorrect<br /></> : null}
          {(password.length <= 0) ? <>Mot de passe requis<br /></> : null}
          {(passwordConfirm.length <= 0) ? <>Confirmation du mot de passe requise<br /></> : null}
          {(password !== passwordConfirm) ? <>Confirmation du mot de passe incorrecte<br /></> : null}
          {(process.env.ON_CLOUD === '1' && captchaToken.length <= 0) ? <>Captcha requis<br /></> : null}
        </Error>
      )
    } else if (onRequestError === 409) {
      return (
        <Error>
          Votre compte existe déja
        </Error>
      )
    } else if (onRequestError === 500) {
      return (
        <Error>
          Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce <ErrorLink target='_blank' href='https://neurocr.ai/contact'>formulaire de contact</ErrorLink>
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    setOnRequest(true)
    setOnError(false)
    setOnRequestError(null)

    e.preventDefault()
    e.stopPropagation()

    if (
      firstname.length <= 0
      || lastname.length <= 0
      || email.length <= 0
      || !EMAIL_REGEX.test(email)
      || password.lenght <= 0
      || passwordConfirm.length <= 0
      || password !== passwordConfirm
      || (process.env.ON_CLOUD === '1' && captchaToken.length <= 0)
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    services
      .register({
        firstname,
        lastname,
        email,
        password,
        captchaToken
      }).then(() => {
        setCreated(true)
        setOnRequest(false)
      }).catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  const handleVerificationSuccess = (
    captchaToken => setCaptchaToken(captchaToken)
  )

  return !authenticated ? (
    <>
      <StyledVideo
        autoPlay
        loop
        muted
        id='background-video'
        poster={getAssetsRootURL(true) + '/images/bg-poster.png'}
      >
        <source src={getAssetsRootURL(true) + '/videos/bg.mp4'} type='video/mp4' />
      </StyledVideo>
      {
        created ? (
          <RegisterContainer>
            <StyledCheckCircleIcon />
            <StyledH2>Inscription terminé</StyledH2>
            <Link to='/login'>Retour à l'accueil</Link>
          </RegisterContainer>
        ) : (
          <RegisterContainer>
            <StyledH1>Inscription</StyledH1>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Prénom'
                  name='firstname'
                  onChange={setFirstname}
                  value={firstname}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Nom'
                  name='lastname'
                  onChange={setLastname}
                  value={lastname}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Email'
                  name='email'
                  onChange={setEmail}
                  value={email}
                  validator={v => !EMAIL_REGEX.test(v) ? 'Non valide' : null}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Mot de passe'
                  name='password'
                  type='password'
                  onChange={setPassword}
                  value={password}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  required
                  dark
                  label='Confirmation du mot de passe'
                  name='password-confirm'
                  type='password'
                  onChange={setPasswordConfirm}
                  value={passwordConfirm}
                  validator={v => password && v != password ? 'Différent du mot de passe' : null}
                />
              </FormGroup>
              {
                process.env.ON_CLOUD === '1' ? (
                  <FormGroupCaptcha>
                    <HCaptcha
                      theme='dark'
                      sitekey={process.env.HCAPTCHA_SITEKEY}
                      onVerify={token => handleVerificationSuccess(token)}
                    />
                  </FormGroupCaptcha>
                ) : null
              }
              {showError()}
              <div className='login-button-container' style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Button
                  primary
                  leftIcon={onRequest ? <SpinnerAnimatedIcon /> : <LoginIcon />}
                  disabled={onRequest}
                  type='submit'
                >
                  Inscription
                </Button>
                <StyledLink to='/login'>Connexion</StyledLink>
              </div>
            </Form>
          </RegisterContainer>
        )
      }
    </>
  ) : <Redirect to={from} />
}
