import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import strftime from 'strftime'
import axios from 'axios'
import qs from 'qs'

import { updateUrl } from '../../../utils'

import SequenceLoader from '../containers/SequenceLoader'
import SearchContext from '../contexts/SearchContext'

import { CF, Container, CenterContainer } from '../components/Grid'
import { Checkbox, SearchIcon, EditIcon, Icon } from '../components/design-system'
import { H1, H3, ColoredLink, Link } from '../components/Typography'
import { Modal } from '../components/Modal'
import Pagination from '../components/Pagination'
import { SmallButton } from '../components/Button'
import SearchInfos from '../components/SearchInfos'
// import SearchFilters from '../components/SearchFilters'
import SequenceThumbnail from '../components/SequenceThumbnail'
import SequencePlayer from '../components/SequencePlayer'
import ProgressBar from '../components/ProgressBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { P } from '../components'
import { faSpinner, faDownload, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons'

import * as services from '../services'

import Filters from '../components/SequencesFilters'
import Details from '../components/SequencesDetails'
import Header from '../components/SequencesHeader'
import Footages from '../components/SequencesFootages'

const SequencesContainer = styled.div`
  width: 90%;
  margin: auto;
`

const BackButtonContainer = styled(CenterContainer)`
  width: 100%;
  margin-bottom: 2rem;
`

const ButtonLink = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  color: #74ccf8;
  text-decoration: none;
  background-color: transparent;

  &:focus { outline: none; }
`

const StyledP = styled(P)`
  font-size: 1.1rem;
`

const fetchDetections = ({ id, offset, sortBy, sortType, cameras, filter }) =>  
  axios
    .get(
      `/api/search/${id}/results`
      + `?offset=${offset}`
      + `&sortBy=${sortBy}`
      + `&sortType=${sortType}`
      + (cameras && cameras.length > 0 ? `&cameras=${cameras.join(',')}` : '')
      + `&filter=${filter}`
    )
    .then(
      ({ data }) => data
    )

const fakeZoneList = [
  { name: 'Zone 1' },
  { name: 'Zone 2' },
  { name: 'Zone 3' },
]

const Detections = ({ location }) => {
  /*let params = qs.parse(location.search.replace(/^\?/, ''))
  let history = useHistory()

  const [editName, setEditName] = useState(true)
  const [editNameValue, setEditNameValue] = useState(params.name)
  const [loading, setLoading] = useState(false)
  const [sequences, setSequences] = useState([])
  const [paging, setPaging] = useState({
    limit: 20,
    offset: 0,
    pageIndex: 0,
    totalItems: 0,
    sortBy: params.sortBy || 'startAt:desc',
    groupBy: params.groupBy || 'confidence',
  })

  const [modalOpen, setModalOpen] = useState(false)
  const [currentSequence, setCurrentSequence] = useState(null)

  const handleSearchName = event => {
    // TODO: edit search name
    setEditNameValue(event.target.value)
  }

  const handleFiltersChange = (pageIndex, groupBy, sortBy) => {
    const { limit } = paging
    const offset = (pageIndex - 1) * limit

    history.pushState({}, '', updateUrl(window.location.href, { groupBy, sortBy }))
    setLoading(true)
    setPaging({ ...paging, pageIndex, groupBy, sortBy })
    fetchSequences({ ...params, limit, offset, groupBy, sortBy }).then(({ sequences, paging }) => {
      setSequences(sequences)
      setPaging(paging)
      setLoading(false)
    })
  }

  const toggleModal = () =>
    setModalOpen(!modalOpen)

  const displaySequence = sequence => {
    setCurrentSequence(sequence)
    setModalOpen(true)
  }

  return (
    <>
      <Details params={params} zones={fakeZoneList} />
      <Header
        sequences={sequences}
        editName={editName}
        setEditName={setEditName}
        handleSearchName={handleSearchName}
        editNameValue={editNameValue}
      />
      <SequencesContainer>
        <Filters
          params={params}
          zones={fakeZoneList}
          tag={params.tag}
          handleFiltersChange={handleFiltersChange}
          paging={paging}
        />
        <Footages
          loading={loading}
          sequences={sequences}
          displaySequence={displaySequence}
          paging={paging}
        />
      </SequencesContainer>
      <Pagination
        activePage={paging.pageIndex}
        itemsCountPerPage={paging.limit}
        totalItemsCount={paging.totalItems}
        pageRangeDisplayed={5}
        onChange={index => handleFiltersChange(index, paging.groupBy, paging.sortBy)}
      />

      <Modal
        isOpen={modalOpen}
        onCloseClick={toggleModal}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}>
        {
          currentSequence &&
          <SequencePlayer
            videos={currentSequence.videos}
            showControls={true}
            playOnHover={false}
            preload="auto"
          />
        }
      </Modal>
    </>
  )*/

  let params = useParams()
  const query = qs.parse(location.search.replace(/^\?/, ''))
  const pageIndex = query.page && parseInt(query.page, 10) || 1

  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [cameras, setCameras] = useState([])
  const [request, setRequest] = useState({
    cameras: [],
    progress: 0
  })
  const [editName, setEditName] = useState(true)
  const [editNameValue, setEditNameValue] = useState(request.name)
  const [sequences, setSequences] = useState([])
  const [stats, setStats] = useState([])
  const [paging, setPaging] = useState({
    limit: 20,
    offset: 0,
    pageIndex,
    totalItems: 0,
    sortBy: query.sortBy || 'score:desc',
    sortType: query.sortType || 'score:confidence',
    cameras: (
      (query.cameras && query.cameras.length > 0) 
      ? [ ...(query.cameras.split(',')) ] 
      : []
    ),
    filter: query.filter || 'all'
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [currentSequence, setCurrentSequence] = useState(null)

  const toggleModal = () =>
    setModalOpen(!modalOpen)

  const handleSearchName = event => {
    // TODO: edit search name
    setEditNameValue(event.target.value)
  }

  const handleFiltersChange = async (
    pageIndex,
    sortType,
    sortBy,
    cameras,
    filter
  ) => {
    const { limit } = paging
    const offset = (pageIndex - 1) * limit

    history.push({
      pathname: location.pathname,
      search: (
        `?page=${pageIndex}`
        + `&sortType=${sortType}`
        + `&sortBy=${sortBy}`
        + (cameras && cameras.length > 0 ? `&cameras=${cameras.join(',')}` : '')
        + `&filter=${filter}`
      )
    })

    setSequences([])
    setLoading(true)
    setPaging({
      ...paging,
      pageIndex,
      sortType,
      sortBy,
      cameras,
      filter
    })

    const { results:_sequences, stats:_stats, nbResults } = (
      await fetchDetections({
        id: request.id,
        offset,
        sortType,
        sortBy,
        cameras,
        filter
      })
    )
    
    setSequences(_sequences)
    setPaging({
      ...paging,
      pageIndex,
      sortType,
      sortBy,
      cameras,
      filter,
      totalItems: nbResults
    })
    setStats(_stats)

    setLoading(false)
  }

  const refresh = async () => {
    const offset = (pageIndex - 1) * paging.limit
    setLoading(true)

    const { request } = await services.fetchStandardSearch(params.id)

    setRequest({
      ...request,
      cameras: (request.cameras || '').split(',')
    })
    const { results:_sequences, stats:_stats, nbResults } = (
      await fetchDetections({
        id: request.id,
        offset,
        sortType: paging.sortType,
        sortBy: paging.sortBy,
        cameras: paging.cameras,
        filter: paging.filter
      })
    )

    setSequences(_sequences)
    setPaging({ ...paging, totalItems: nbResults })
    setStats(_stats)

    setTimeout(() => setLoading(false), 500)
  }

  useEffect(() => {
    let _t

    const refetch = async () => {
      const offset = (pageIndex - 1) * paging.limit
      const { request } = await services.fetchStandardSearch(params.id)

      setRequest({
        ...request,
        cameras: (request.cameras || '').split(',')
      })
      if (request.status == 'done') {
        setLoading(true)

        const { results:_sequences, stats:_stats } = (
          await fetchDetections({
            id: request.id,
            offset,
            sortType: paging.sortType,
            sortBy: paging.sortBy,
            cameras: paging.cameras,
            filter: paging.filter
          })
        )

        setSequences(_sequences)
        setStats(_stats)
        setTimeout(() => setLoading(false), 500)
        return
      }
      _t = setTimeout(refetch, 4000)
    }

    refresh()
    _t = setTimeout(refetch, 1000)
    return () => _t && clearTimeout(_t)
  }, [])

  const displaySequence = (_, __, videos) => {
    setCurrentSequence({ videos })
    setModalOpen(true)
  }

  return (
    <>
      {!loading && <ProgressBar value={Math.ceil(request.progress)} hideAt={100} />}
      <Details
        request={request}
        zones={fakeZoneList}
        loading={loading}
      />
      <Header
        stats={stats}
        editName={editName}
        setEditName={setEditName}
        handleSearchName={handleSearchName}
        editNameValue={editNameValue}
      />
      {
        request.progress < 100 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledP style={{ textAlign: 'center', margin: '1rem 0' }}>
              Traitement des videos en cours ({Math.ceil(request.progress)}%)
              {' - '}
              <ButtonLink onClick={refresh}>
                <FontAwesomeIcon icon={loading ? faSpinner : faDownload} spin={loading} style={{ marginRight: '5px' }} />
                {!loading && 'Rafraichir'}
              </ButtonLink>
            </StyledP>
          </div>
        )
      }
      <SequencesContainer>
        {
          sequences.length > 0 && (
            <Filters
              params={request}
              zones={fakeZoneList}
              tag={request.tag}
              paging={paging}
              handleFiltersChange={handleFiltersChange}
              searchCameras={request.cameras}
            />
          )
        }
        <Footages
          loading={loading}
          sequences={sequences}
          displaySequence={displaySequence}
          paging={paging}
        />
      </SequencesContainer>
      {
        paging.totalItems > 0 && (
          <Pagination
            activePage={paging.pageIndex}
            itemsCountPerPage={paging.limit}
            totalItemsCount={paging.totalItems}
            pageRangeDisplayed={5}
            onChange={index => handleFiltersChange(index, paging.sortType, paging.sortBy)}
            getPageUrl={
              i => `?page=${i}&sortType=${paging.sortType}&sortBy=${paging.sortBy}&favorite=${paging.favorite}&hidden=${paging.hidden}}`
            }
          />
        )
      }
      <BackButtonContainer>
        <SmallButton onClick={() => history.push('/search')}>Retour</SmallButton>
      </BackButtonContainer>
      <Modal
        isOpen={modalOpen}
        onCloseClick={toggleModal}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        {
          currentSequence &&
            <SequencePlayer
              videos={currentSequence.videos}
              showControls={true}
              playOnHover={false}
              preload="auto"
            />
        }
      </Modal>
    </>
  )
}

export default Detections
