import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'

export default ({ fromUrl, children, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [sequence, setSequence] = useState({})

  useEffect(() => {
    setIsLoading(true)

    axios.get(fromUrl).then(({ data: { sequence } }) => {
      setSequence(sequence)
      setIsLoading(false)
    })
  }, [])

  return (
    <Fragment>
      {React.cloneElement(children, { ...sequence, ...props })}
    </Fragment>
  )
}
