import React, { useContext } from 'react'
import styled from 'styled-components'

import { H3 } from '../components/Typography'
import { Checkbox } from '../components/design-system'
import { LabelBody, Radio } from '../components/Form'
import SearchContext from '../contexts/SearchContext'
import { Row, CenterContainer } from '../components/Grid'
import { Select } from '../components/Input'
import { SmallButton } from '../components/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const TagContainer = styled(Row)`
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: baseline;
`

const Tag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TagLabel = styled(LabelBody)`
  display: block;
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
`
const TagRadio = styled(Radio)`
  display: block !important;
  margin: 0 auto;
`

const InfoContainer = styled(CenterContainer)`
  color: #999999;
  width: 70%;
  margin: auto;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const SelectCriteriaContainer = styled.div`
  display: flex;
  column-gap: 3rem;
  justify-content: center;
`

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
`

const TagImg = styled.img`
  opacity: ${({ selected }) => selected ? '1' : '.2'};
  transition: opacity 0.25s ease-out;
  user-select: none;
`

const SearchPerson = ({ form, setForm }) => {
  return null
  /*return (
    <>
      <SelectCriteriaContainer>
        <SelectContainer>
          <Select
            label='Genre'
            name='gender'
            value={form?.gender || ''}
            onChange={v => setForm({...form, gender: v})}
          >
            <option>Inconnu</option>
            <option value='male'>Homme</option>
            <option value='female'>Femme</option>
          </Select>
        </SelectContainer>
        <SelectContainer>
          <Select
            label='Âge'
            name='age'
            value={form?.age || ''}
            onChange={v => setForm({...form, age: v})}
          >
            <option>Inconnu</option>
            <option value='adult'>Adulte (+18)</option>
            <option value='infant'>Enfant</option>
          </Select>
        </SelectContainer>
        <SelectContainer>
          <Select
            label='Couleur vêtements haut'
            name='top-color'
            value={form?.topColor || ''}
            onChange={v => setForm({...form, topColor: v})}
          >
            <option>Inconnue</option>
            <option value='black'>Noir</option>
            <option value='blue'>Bleu</option>
            <option value='gray'>Gris</option>
            <option value='green'>Vert</option>
            <option value='red'>Rouge</option>
            <option value='white'>Blanc</option>
            <option value='yellow'>Jaune</option>
          </Select>
        </SelectContainer>
        <SelectContainer>
          <Select
            label='Couleur vêtements bas'
            name='bottom-color'
            value={form?.bottomColor || ''}
            onChange={v => setForm({...form, bottomColor: v})}
          >
            <option>Inconnue</option>
            <option value='black'>Noir</option>
            <option value='blue'>Bleu</option>
            <option value='gray'>Gris</option>
            <option value='green'>Vert</option>
            <option value='red'>Rouge</option>
            <option value='white'>Blanc</option>
            <option value='yellow'>Jaune</option>
          </Select>
        </SelectContainer>
      </SelectCriteriaContainer>
      <CenterContainer>
        <Checkbox
          onChange={() => {}}
          label='Tracer le parcours (nécessite plus de ressources)'
          disabled
        />
      </CenterContainer>
    </>
  )*/
}

const SearchVehicle = ({
  carMakes,
  carModels,
  carColors,
  selectModel,
  selectColor,
  selectMake,
  isOnCarMakesLoading,
  isOnCarModelsLoading,
  isOnCarColorsLoading,
  form
}) => (
  <>
    <SelectCriteriaContainer>
      <SelectContainer>
        {
          isOnCarMakesLoading
          ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              style={{ marginRight: '5px' }}
            />
          )
          : (
            <Select
              label='Marque'
              name='make'
              value={form.make || ''}
              onChange={selectMake}
            >
              <option>Inconnue</option>
              {
                carMakes.map(({ name, displayName }) => (
                  <option key={`carmake-${name}`} value={name}>{displayName}</option>
                ))
              }
            </Select>
          )
        }
      </SelectContainer>
      <SelectContainer>
        {
          isOnCarModelsLoading
          ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              style={{ marginRight: '5px' }}
            />
          )
          : (
            <Select
              label='Modèle'
              name='make'
              value={form.model || ''}
              onChange={selectModel}
            >
              <option>Inconnu</option>
              {
                carModels.map(({ name, displayName }) => (
                  <option key={`carmake-${name}`} value={name}>{displayName}</option>
                ))
              }
            </Select>
          )
        }
      </SelectContainer>
      <SelectContainer>
        {
          isOnCarMakesLoading
          ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              style={{ marginRight: '5px' }}
            />
          )
          : (
            <Select
              label='Couleur'
              name='make'
              value={form.color || ''}
              onChange={selectColor}
            >
              <option>Inconnue</option>
              {
                carColors.map(({ name, displayName }) => (
                  <option key={`carcolor-${name}`} value={name}>{displayName}</option>
                ))
              }
            </Select>
          )
        }
      </SelectContainer>
    </SelectCriteriaContainer>
    <CenterContainer>
      <Checkbox
        onChange={() => {}}
        label='Tracer le parcours (nécessite plus de ressources)'
        disabled
      />
    </CenterContainer>
  </>
)

const SearchFace = ({ }) => {
  /* TODO: Show library if not empty */
  return (
    <InfoContainer>
      <H3>La bibliothèque est vide</H3>
      NeuroCop détecte les visages par profilage. Pour fournir à l’outil le profil recherché,
      il faut le choisir dans la bibliothèque de visages, or elle est actuellement vide.
      <SmallButton>Ajouter des profils à la bibliothèque</SmallButton>
    </InfoContainer>
  )
}

const SearchCriteriaSection = ({
  selected,
  selectTag,
  selectModel,
  selectColor,
  selectMake,
  form,
  setForm,
  isOnCarMakesLoading,
  isOnCarModelsLoading,
  isOnCarColorsLoading
}) => {
  const [{ tags, carMakes, carModels, carColors }] = useContext(SearchContext)

  return (
    <>
      <TagContainer>
        {
          tags.map(({ name, displayName, disabled }) => {
            let disable = disabled

            return (
              <Tag as='label' value={name} key={`tag-${name}`}>
                <TagImg src={`images/criteria/${name}.svg`} selected={selected === name} />
                <TagLabel>{displayName}</TagLabel>
                <TagRadio
                  name='tag'
                  value={name}
                  onChange={selectTag}
                  checked={selected === name}
                  disabled={disable}
                />
              </Tag>
            )
          })
        }
      </TagContainer>
      {
        (selected === 'car') &&
        <SearchVehicle
          carMakes={carMakes}
          carColors={carColors}
          carModels={carModels}
          isOnCarMakesLoading={isOnCarMakesLoading}
          isOnCarModelsLoading={isOnCarModelsLoading}
          isOnCarColorsLoading={isOnCarColorsLoading}
          selectModel={selectModel}
          selectColor={selectColor}
          selectMake={selectMake}
          form={form}
        />
      }
      {
        selected === 'face' && <SearchFace form={form} setForm={setForm} />
      }
      {
        process.env.NODE_ENV != 'staging' && selected === 'person' &&
        <SearchPerson form={form} setForm={setForm} />
      }
    </>
  )
}

export default SearchCriteriaSection
