import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'

import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavbarCollapse,
  NavbarNav,
  NavItem,
  NavLink,
  BurgerMenuIcon
} from '../components'

import Login from './Login'

const StyledBurgerMenuIcon = styled(BurgerMenuIcon)`
  color: ${({ theme }) => theme.primaryColor};
  width: 58px;
  height: 14px;
`

export default () => {
  const [{ loading, authenticated }] = useContext(AuthContext)
  const [collapsed, setCollapsed] = useState(true)
  const el = useRef(null)

  const handleClick = (e) => {
    if (el.current && el.current.contains(e.target))
      setCollapsed(!collapsed)
    else
      setCollapsed(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () =>
      document.removeEventListener('click', handleClick, true);
  }, [collapsed])

  return (
    (loading || !authenticated)
    ? null
    : (
      <Navbar>
        <NavbarBrand to="/home">
          <img src="/images/neurocop_logo.svg" width="112px" height="40px" />
        </NavbarBrand>
        <NavbarToggler ref={el}>
          <StyledBurgerMenuIcon />
        </NavbarToggler>
        <NavbarCollapse collapsed={collapsed}>
          <NavbarNav>
            <NavItem>
              <NavLink to="/search">Rechercher</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/vpi">VPI</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/alert">Alertes</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/cameras">Cameras</NavLink>
            </NavItem>
          </NavbarNav>
          <Login />
        </NavbarCollapse>
      </Navbar>
    )
  )
}
