import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faEye, faLowVision } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'

import { Label, Checkbox } from '../components/Form'

const FiltersUl = styled.ul`
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
`
const FilterLi = styled.li`
  display: inline-block;
  margin-right: 16px;
`

const CheckboxLabel = styled(Label)`
  font-weight: normal;

  ${({ checked }) => checked && `
    color: #74ccf8
  `}
`

export default ({ onlyFavorites, onlyHidden, onChange, ...props }) => {

  const handleFavoritesChange = e =>
    onChange(e.target.checked, onlyHidden)

  const handleHiddenChange = e =>
    onChange(onlyFavorites, e.target.checked)

  return (
    <FiltersUl>
      {!onlyHidden &&
        <FilterLi>
          <CheckboxLabel checked={onlyFavorites}>
            <Checkbox onChange={handleFavoritesChange} value={onlyFavorites} style={{ display: 'none' }} />
            {onlyFavorites ? <FontAwesomeIcon icon={faHeart} /> : <FontAwesomeIcon icon={farHeart} />}
            {' '} Uniquement les favoris
          </CheckboxLabel>
        </FilterLi>
      }
      {!onlyFavorites &&
        <FilterLi>
          <CheckboxLabel checked={onlyHidden}>
            <Checkbox onChange={handleHiddenChange} value={onlyHidden} style={{ display: 'none' }} />
            <FontAwesomeIcon icon={faLowVision} /> Uniquement les cachés
          </CheckboxLabel>
        </FilterLi>
      }
    </FiltersUl>
  )
}
