import axios from 'axios'

export const fetchAllStandardSearch = params =>
  axios
    .get(`/api/search/requests`, { params })
    .then(({ data }) => data)

export const fetchStandardSearch = id =>
  axios
    .get(`/api/search/requests/${id}`)
    .then(({ data }) => data)

export const addStandardSearch = params =>
  axios
    .post(`/api/search/requests`, params)
    .then(({ data }) => data)

export const fetchCameras = () =>
  axios.get('/api/cameras').then(({ data }) => data)

export const fetchCarMakes = () =>
  axios.get('/api/cars/makes').then(({ data }) => data)

export const fetchCarModels = params =>
  axios.get(`/api/cars/makes/${make}/models`, params).then(({ data }) => data)
