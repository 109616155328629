import React, { Fragment, useState, useEffect, useContext } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import axios from 'axios'

import { base64toBlob } from '../../../utils'

import * as services from '../services'

import SearchContext from '../contexts/SearchContext'

import { SearchSection } from '../components/SearchSection'
import { H1, P, H3, Error } from '../components/Typography'
import { Button, SmallButton } from '../components/Button'
import { Modal, ModalTitle, ModalBody } from '../components/Modal'
import { AddCircleFilledIcon } from '../components/design-system'
import { TextInput, Select } from '../components/Input'
import SearchCamerasSection from '../components/SearchCamerasSection'

import EditCamera from '../components/EditCamera'
import SectorCreation from '../components/SectorCreation'

const Options = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Option = styled.li`
  display: inline-block;
  margin: 0 10px 0 0;
`

const StyledAddCircleFilledIcon = styled(AddCircleFilledIcon)`
  cursor: pointer;
  transition: color .2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`


const DeleteMultipleCameraModal = ({
  selectedCameras,
  setSelectedCameras,
  handleDeleteMultiple,
  deletingMultiple
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => setSelectedCameras(null)

  const handleDelete = () => {
    setDeleting(true)
    handleDeleteMultiple(selectedCameras)
    setDeleting(false)
  }

  return (
    <Modal
      isOpen={deletingMultiple}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}>
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Supprimer cette configuration ces caméras supprimera également toutes les videos et recherches associées.
          <br />Cette action est irréversible.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'staging'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}



const DeleteCameraModal = ({
  cameraToDelete,
  setCameraToDelete,
  deleteCamera,
  fetchCameras
}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => setCameraToDelete(null)

  const handleDelete = () => {
    setDeleting(true)
    deleteCamera(cameraToDelete.id).then(() => {
      fetchCameras()
      handleCancel()
      setDeleting(false)
    })
  }

  return (
    <Modal
      isOpen={cameraToDelete && cameraToDelete.id}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}>
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Supprimer cette configuration de caméra supprimera également toutes les videos et recherches associées.
          <br />Cette action est irréversible.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'staging'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}


const DeleteSectorModal = ({
  sectorToDelete,
  setSectorToDelete,
  updateAllSectors,

}) => {
  const [deleting, setDeleting] = useState(false)

  const handleCancel = () => setSectorToDelete(null)

  const handleDelete = () => {
    setDeleting(true)
    services.deleteSector(sectorToDelete?.id).then(() => {
      handleCancel()
      setSectorToDelete(null)
      setDeleting(false)
      updateAllSectors()
    })
  }

  return (
    <Modal
      isOpen={sectorToDelete && sectorToDelete?.id}
      onCloseClick={handleCancel}
      onBackgroundClick={handleCancel}
      onEscapeKeydown={handleCancel}>
      <ModalTitle>Êtes-vous sûr de vouloir faire ça ?</ModalTitle>
      <ModalBody>
        <P>
          Souhaitez-vous vraiment supprimer le secteur <b>{sectorToDelete?.name}</b> ?
          <br />Cette action est irréversible.
        </P>
        <Options>
          <Option>
            <Button red
              spin={deleting}
              disabled={deleting || process.env.NODE_ENV === 'staging'}
              onClick={handleDelete}
            >
              Supprimer
            </Button>
          </Option>
          <Option>
            <Button onClick={handleCancel}>Annuler</Button>
          </Option>
        </Options>
      </ModalBody>
    </Modal>
  )
}

const InputColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1.5rem;

  & > *:not(:nth-child(2)) {
    width: 260px;
  }
`

const SearchCamerasSectionContainer = styled.div`
  z-index: 10;
  width: 100%;
  margin-top: 0 !important;
  position: fixed;
  height: 300px;
  background-color: #fff;
  bottom: 0;
  opacity: 1;
  height: ${({ toggle }) => toggle ? 'auto' : '0'};
  padding: ${({ toggle }) => toggle ? '2rem 0' : '0'};
  opacity: ${({ toggle }) => toggle ? '1' : '0'};
  ${({ toggle }) => toggle && 'margin-top: 2rem;'}
  transition: opacity .6s ease-in-out, padding .6s ease-in-out;
  -moz-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${SmallButton} {
    margin: 1.5rem .5rem 0 .5rem;
  }

  ${InputColumns}, button {
    ${({ toggle }) => !toggle && 'display: none;'};
  }
`

const SectorsSearchSection = styled(SearchSection)`
  border-bottom none;
  ${({ toggle }) => toggle && 'margin-bottom: 9.7rem;'};
  transition: margin-bottom .6s ease-in-out;
`

const StyledH3 = styled(H3)`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  letter-spacing: 0;
`
let cancelTest = () => { }
const testCamera = params => axios.post('/api/cameras/test', params, {
  cancelToken: new axios.CancelToken(c => cancelTest = c)
}).then(({ data }) => data)

const createCamera = params => axios.post('/api/cameras', params)
const updateCamera = ({ id, ...params }) => axios.put(`/api/cameras/${id}`, params)
const deleteCamera = id => axios.delete(`/api/cameras/${id}`)
const deleteMultipleCamera = ids => axios.delete('/api/cameras', { data: { ids } })

export default () => {
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)
  const [testing, setTesting] = useState(false)
  const [creating, setCreating] = useState(false)
  const [uriToTest, setUriToTest] = useState('rtsp://')
  const [testingError, setTestingError] = useState(null)

  const [deletingMultiple, setDeletingMultiple] = useState(null)
  const [cameraToDelete, setCameraToDelete] = useState(null)
  const [selectedCamera, setSelectedCamera] = useState(null)
  const [selectedCameras, setSelectedCameras] = useState([])

  const [sectorToDelete, setSectorToDelete] = useState(null)
  const [selectedSector, setSelectedSector] = useState('')
  const [allSectors, setAllSectors] = useState(null)
  const [newSector, setNewSector] = useState('')

  const updateAllSectors = () => {
    services.fetchAllSectors({})
    .then(({ requests }) => {
      setAllSectors(requests)
    })
  }

  useEffect(() => {
    updateAllSectors()
    fetchCameras()
  }, [])

  const onSubmitTest = async e => {
    e.preventDefault()
    let error = false

    await axios.get('/api/cameras/uri', {
      params: {
        uri: String(uriToTest)
      }
    }).then(({ data }) => {
      if (data?.id) {
        error = true
        setTestingError(`URL déjà utilisée par caméra "${data?.name}".`)
      } else {
        error = false
        setTestingError(null)
      }
    }).catch((thrown) => {
      error = true
      if (axios.isCancel(thrown)) {
        setTestingError('Error testing the URI.')
      }
    })

    if (!error) {
      setTesting(true)
      testCamera({ input: uriToTest }).then(({ img: thumbnail }) => {
        setSelectedCamera({ input: uriToTest, thumbnail, detect: true, saveVideo: true })
        setTesting(false)
        setTestingError(null)
        setCreating(true)
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
          setTesting(false)
          setTestingError('Error testing the URI.')
        }
      })
    } else {
      setTesting(false)
    }
  }

  const handleDeleteMultiple = cameras => {
    deleteMultipleCamera(cameras).then(() => {
      fetchCameras()
      setSelectedCameras([])
      setDeletingMultiple(false)
    })
  }
  const handleDeleteCamera = camera => setCameraToDelete(camera)
  const handleModifyCamera = camera => setSelectedCamera(camera)

  const handleSectorName = value => {
    const regexTest = /(^[a-zA-Z0-9\_\-\ ]+$)|^$/g.test(value);

    if (regexTest) setNewSector(value)
  }

  const onSubmitSector = (e, noCamera) => {
    e.preventDefault()

    if (newSector && !selectedSector) {
      services.addSector({
        name: newSector,
        cameras: selectedCameras && selectedCameras?.length !== 0 && !noCamera
          ? selectedCameras.join(',')
          : null
      }).then(() => updateAllSectors())
    }

    if (selectedSector && !newSector) {
      services.updateSector(selectedSector, {
        cameras: selectedCameras && selectedCameras?.length !== 0 && !noCamera
          ? selectedCameras.join(',')
          : null
      })
      .then(() => updateAllSectors())
    }

    setSelectedCameras([])
    setNewSector('')
    setSelectedSector('')
  }

  const AddButton = () => {
    return (
      <StyledAddCircleFilledIcon onClick={e => onSubmitSector(e, true)} />
    )
  }
  return (
    <>
      <H1>Caméras</H1>
      <SearchSection
        title='Ajouter/Modifier une caméra'
        icon='/images/filters.svg'
        description='Ajouter une caméra via son URL RTP, RTSP, HTTP ou HTTPS. Modifiez via les caméras enregistrées ci-dessous.'
      >
        <EditCamera
          createCamera={createCamera}
          creating={creating}
          fetchCameras={fetchCameras}
          onSubmitTest={onSubmitTest}
          selectedCamera={selectedCamera}
          setCreating={setCreating}
          setSelectedCamera={setSelectedCamera}
          setUriToTest={setUriToTest}
          testing={testing}
          testingError={testingError}
          uriToTest={uriToTest}
          updateCamera={updateCamera}
        />
      </SearchSection>
      <SearchSection
        title='Caméras enregistrées'
        icon='/images/camera.png'
      >
        <SearchCamerasSection
          standalone
          tooltip
          camerasList={cameras}
          toggle={false}
          deleteCamera={handleDeleteCamera}
          modifyCamera={handleModifyCamera}
          selectedCameras={selectedCameras}
          setSelectedCameras={setSelectedCameras}
          sectors={allSectors}
        />
      </SearchSection>
      <SectorsSearchSection
        title='Gestion des secteurs'
        icon='/images/filters.svg'
        description={<>
          <strong>Les secteurs sont un regroupement de caméras prédéfini, ils facilitent vos recherches. </strong>
          Créer vos secteurs puis placez-y les caméras concernées en les sélectionnant via la section « Caméras enregistrées » ci-dessus.
        </>}
        toggle={selectedCameras && selectedCameras?.length !== 0}
      >
        <SectorCreation
          allSectors={allSectors}
          newSector={newSector}
          setNewSector={setNewSector}
          handleSectorName={handleSectorName}
          onSubmitSector={onSubmitSector}
          addButton={AddButton}
          setSectorToDelete={setSectorToDelete}
        />
      </SectorsSearchSection>
      <DeleteCameraModal
        cameraToDelete={cameraToDelete}
        setCameraToDelete={setCameraToDelete}
        deleteCamera={deleteCamera}
        fetchCameras={fetchCameras}
      />
      <DeleteSectorModal
        sectorToDelete={sectorToDelete}
        setSectorToDelete={setSectorToDelete}
        updateAllSectors={updateAllSectors}
      />
      <DeleteMultipleCameraModal
        selectedCameras={selectedCameras}
        setSelectedCameras={setSelectedCameras}
        handleDeleteMultiple={handleDeleteMultiple}
        deletingMultiple={deletingMultiple}
      />
      <SearchCamerasSectionContainer toggle={selectedCameras && selectedCameras?.length !== 0}>
        <StyledH3>
          {
            selectedCameras?.length && selectedCameras?.length > 1
              ? `${selectedCameras?.length} caméras selectionnées`
              : '1 caméra selectionnée'
          }
        </StyledH3>
        <InputColumns>
          <form onSubmit={e => onSubmitSector(e, true)}>
            <TextInput
              label='Créer nouveau secteur'
              icon={AddButton}
              value={newSector}
              onChange={v => handleSectorName(v)}
            />
          </form>
          <div>OU</div>
          <Select
            value={selectedSector}
            onChange={v => setSelectedSector(v)}
          >
            <option>Ajouter à un secteur existant</option>
            {
              allSectors &&
              allSectors.map((sector, i) => (
                <option
                  key={`${sector.name}-${i}`}
                  value={sector.id}
                >
                  {sector.name}
                </option>
              ))
            }
          </Select>
        </InputColumns>
        <div>
          <SmallButton onClick={e => onSubmitSector(e)}>Ajouter au secteur</SmallButton>
          <SmallButton
            onClick={() => setDeletingMultiple(true)}
            color='#F61A1A'
            hoverColor='#cc1212'
          >
            Supprimer {
              selectedCameras?.length && selectedCameras?.length > 1
                ? 'caméras'
                : 'caméra'
            }
          </SmallButton>
        </div>
      </SearchCamerasSectionContainer>
    </>
  )
}
