import React from 'react'
import ReactDOM from 'react-dom'
import App from './js/App'
// import analytics from './js/analytics'

// if (process.env.NODE_ENV === 'staging') {
//   analytics()
// }

ReactDOM.render(<App />, document.getElementById('app'))
