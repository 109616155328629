import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import qs from 'qs'

import * as services from '../services'

import SearchContext from '../contexts/SearchContext'

import { CenterContainer} from '../components/Grid'
import { PageTitle, Error, Link } from '../components/Typography'
import { SearchSection } from '../components/SearchSection'
import { EditIcon, SearchIcon } from '../components/design-system'
import { SmallButton, BigButton } from '../components/Button'
import { TextInput } from '../components/Input'
import HistoryTable from '../components/HistoryTable'

import SearchDateSection from '../components/SearchDateSection'
import SearchCriteriaSection from '../components/SearchCriteriaSection'
import SearchCamerasSection from '../components/SearchCamerasSection'

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;
`

const StyledSearchSection = styled(SearchSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;' }
  padding-bottom: ${({ displayCameras }) => displayCameras ? '0' : '2rem'} !important;
`

const HistorySearchSection = styled(SearchSection)`
  text-align: center;

  ${SmallButton} {
    margin-top: 2rem;
  }
`

const Search = ({ history }) => {
  const [
    { cameras },
    { fetchCameras, fetchCarMakes, fetchCarModels }
  ] = useContext(SearchContext)

  const [displayCameras, setDisplayCameras] = useState(false)
  const [researchName, setResearchName] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const [camerasLoading, setCamerasLoading] = useState(true)
  const [carMakesLoading, setCarMakesLoading] = useState(true)
  const [carModelsLoading, setCarModelsLoading] = useState(false)
  const [requests, setRequests] = useState([])
  const [allSectors, setAllSectors] = useState(null)
  const [form, setForm] = useState({
    cameras: [],
    tag: 'car',
    // -1h in ms
    since: new Date(Date.now() - 60 * 60 * 1000),
    until: new Date(),
  })

  useEffect(() => {
    fetchCameras()
      .then(({ cameras }) => {
        setForm({
          ...form,
          cameras: cameras.map(({ id }) => id)
        })
        setCamerasLoading(false)
      })
    fetchCarMakes()
      .then(() => {
        setCarMakesLoading(false)
      })
    services.fetchAllSectors({})
      .then(({ requests }) => {
        setAllSectors(requests)
      })
  }, [])

  useEffect(() => {
    services
      .fetchAllStandardSearch()
      .then(({ requests }) => setRequests(requests))
  }, [])

  const handleSetResearchName = value => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value);

    if (regexTest) setResearchName(value)
  }

  const handleSubmit = event => {
    event.preventDefault()

    let params = {
      tag: form.tag,
      name: researchName,
      cameras: form.cameras.join(','),
      since: form.since.getTime(),
      until: form.until.getTime(),
    }
    const vehiculeParams = {
      make: form?.make,
      model: form?.model,
      color: form?.color,
    }
    const personParams = {
      age: form?.age,
      gender: form?.gender,
      topColor: form?.topColor,
      bottomColor: form?.bottomColor,
    }

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    setSubmitError(null)
    if (form.tag === 'person') {
      params = {
        ...params,
        ...personParams,
      }
    }
    if (form.tag === 'car') {
      params = {
        ...params,
        ...vehiculeParams,
      }
    }

    setSubmitError(null)
    services.addStandardSearch(params)
      .then(({ request }) => {
        history.push(`/search/${request.id}?${qs.stringify(params)}`)
      })
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const selectTag = (e) => setForm({ ...form, tag: e.target.value, make: null, model: null })

  const selectMake = value => {
    setForm({ ...form, make: value })
    setCarModelsLoading(true)
    fetchCarModels(value)
      .then(() => {
        setCarModelsLoading(false)
      })
  }

  const selectModel = value => setForm({ ...form, model: value })
  const selectColor = value => setForm({ ...form, color: value })
  const setDate = (name, value) => setForm({ ...form, [name]: value })

  const tableHeader = [
    {
      name: 'Date',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Nom',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Début',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Fin',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Périmètre',
      sort: list => list.slice().sort((a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      }),
    },
    {
      name: 'Type',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Status',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    { name: 'Résultats' }
  ]

  return (
    <>
      <PageTitle>Nouvelle recherche</PageTitle>
      <StyledSearchSection
        displayCameras={displayCameras}
        title='Périmètre'
        icon='/images/camera.png'
        description={
          <>
            <strong>Filtrer les caméras</strong> concernées par la recherche.
            Au besoin, regroupez plusieurs caméras par secteurs via le <Link to='/cameras'>menu Caméras</Link>.
          </>
        }
      >
        <SmallButton type='button' onClick={toggleDisplayCameras} primary>
          {displayCameras ? 'Masquer les caméras' : 'Afficher les caméras'}
        </SmallButton>
      </StyledSearchSection>
      <SearchCamerasSection
        camerasList={cameras}
        loading={camerasLoading}
        sectors={allSectors}
        selectedCameras={form.cameras}
        setSelectedCameras={v => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
      />
      <SearchSection
        title='Critères'
        icon='/images/filters.svg'
        description={<><strong>Indiquez sur quoi</strong> porte la recherche.</>}
      >
        <SearchCriteriaSection
          selected={form.tag}
          selectTag={selectTag}
          selectModel={selectModel}
          selectColor={selectColor}
          selectMake={selectMake}
          isOnCarMakesLoading={carMakesLoading}
          isOnCarModelsLoading={carModelsLoading}
          isOnCarColorsLoading={false}
          form={form}
          setForm={setForm}
        />
      </SearchSection>
      <SearchSection
        title='Période'
        icon='/images/calendar.png'
        description={<><strong>Définissez la période</strong> de recherche.</>}
      >
        <SearchDateSection until={form.until} since={form.since} setDate={setDate} />
      </SearchSection>
      <SearchSection>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <TextInput
              label='Nommer la recherche'
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            {
              submitError && <Error>{submitError}</Error>
            }
            <BigButton
              type='submit'
              leftIcon={<SearchIcon />}
              disabled={!form.cameras.length || !researchName}
            >
              Lancer la recherche
            </BigButton>
          </form>
        </NameInputContainer>
      </SearchSection>
      <HistorySearchSection title='Recherches récentes' icon='/images/search.svg'>
        <HistoryTable
          requests={requests}
          header={tableHeader}
          type='Search'
        />
        <SmallButton onClick={() => history.push('/account/history')}>Voir l'historique complet</SmallButton>
      </HistorySearchSection>
    </>
  )
}

export default Search
