import React from 'react'
import styled from 'styled-components'

import { H1 } from '../components/Typography'
import { Section, Container, Columns } from '../components/Grid'

const StyledSection = styled(Section)`
  padding: 0 4%;
`

const Player = styled.video`
  width: 100%;
`

const Watch = () => (
  <div>
    <H1>Surveillance</H1>
    <StyledSection>
      <Columns nb={6}>
        <Player autoPlay muted>
          <source src="/home/neuronalys/Videos/neurocop/jackson_hole/1582736400.mp4" type="video/mp4" />
        </Player>
      </Columns>
      <Columns nb={6}>
        <Player autoPlay muted>
          <source src="/home/neuronalys/Videos/neurocop/la_grange/1582736400.mp4" type="video/mp4" />
        </Player>
      </Columns>
    </StyledSection>
    <StyledSection>
      <Columns nb={6}>
        <Player autoPlay muted>
          <source src="/home/neuronalys/Videos/neurocop/london/1582736400.mp4" type="video/mp4" />
        </Player>
      </Columns>
      <Columns nb={6}>
        <Player autoPlay muted>
          <source src="/home/neuronalys/Videos/neurocop/A3/1582736400.mp4" type="video/mp4" />
        </Player>
      </Columns>
    </StyledSection>
  </div>
)

export default Watch
