import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme as DesignSystemTheme } from './components/design-system/Theme'

const theme = {
  ...DesignSystemTheme
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Blinker;
    font-weight: 300;
    src: url("/fonts/blinker/Blinker-Light.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("/fonts/blinker/Blinker-SemiBold.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 700;
    src: url("/fonts/blinker/Blinker-Bold.ttf");
  }
  @font-face {
    font-family: Arimo;
    font-weight: 400;
    src: url("/fonts/arimo/Arimo-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url("/fonts/roboto/Roboto-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 700;
    src: url("/fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 400;
    src: url("/fonts/saira/Saira-Regular.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 200;
    src: url("/fonts/saira/Saira-Light.ttf");
  }
  :root, body {
    height: 100%;
  }
  body {
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #222;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
