import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import qs from 'qs'

import { TextInput } from '../components/Input'
import { ColoredLink, Error } from '../components/Typography'
import { AddCircleFilledIcon } from '../components/design-system'
import { Container, CenterContainer } from '../components/Grid'

const PlateInput = styled(TextInput)`
  input {
    font-family: Blinker !important;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
`

const PlateInputContainer = styled(CenterContainer)`
  width: 25%;
  margin: auto;
  flex-direction: column;
`

const PlatesContainer = styled(Container)`
  width: 100%;
  text-align: center;
  display: ${({ display }) => display};
  border-top: 1px solid #E5E5E5;
  margin-top: 2rem !important;
  padding-top: 2rem !important;
`

const PlateContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 .8rem;
`

const Plates = styled.div`
  width: fit-content;
  font-family: Blinker;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  border: 1px solid #000;
  padding: 0 1.5rem;
  border-radius: 5px;
`

const DeletePlate = styled(ColoredLink)`
  color: ${({ theme }) => theme.errorRed};
  font-size: .75rem;
  margin-top: .2rem;
`

const StyledAddCircleFilledIcon = styled(AddCircleFilledIcon)`
  cursor: pointer;
  transition: color .2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`

const PlateSearch = ({ plates, setPlates, addPlate, newPlate, handleChange, plateError }) => {
  const AddButton = () => {
    return (
      <StyledAddCircleFilledIcon onClick={addPlate} />
    )
  }

  return (
    <>
      <PlateInputContainer>
        <form onSubmit={addPlate}>
          <PlateInput
            icon={AddButton}
            value={newPlate}
            onChange={handleChange}
          />
        </form>
      </PlateInputContainer>
      {
        plateError && <Error>{plateError}</Error>
      }
      <PlatesContainer display={plates.length === 0 ? 'none' : 'block'}>
        {
          plates.map((plate, i) => (
            <PlateContainer key={`plate-${plate}-${i}`}>
              <Plates>{plate}</Plates>
              <DeletePlate onClick={() => setPlates(plates.filter(ee => plate !== ee))}>
                Suppr.
              </DeletePlate>
            </PlateContainer>
          ))
        }
      </PlatesContainer>
    </>
  )
}

export default PlateSearch
