import React, { createContext, useReducer } from 'react';
import axios from 'axios'

const initialState = {
  cameras: [],
  tags: [
    { "name": "car", "displayName": "Voitures", "disabled": false },
    { "name": "heavyVehicles", "displayName": "Véhicules lourds", "disabled": false },
    { "name": "twoWheeled", "displayName": "2 roues", "disabled": false },
    { "name": "person", "displayName": "Personnes", "disabled": false },
    ...(process.env.NODE_ENV != 'staging' ? [{ "name": "face", "displayName": "Visages", "disabled": true }] : []),
    { "name": "pet", "displayName": "Animaux", "disabled": false }
  ],
  carMakes: [],
  carModels: [],
  carColors: [
    { "name": "silver", "displayName": "Argent" },
    { "name": "beige", "displayName": "Beige" },
    { "name": "white", "displayName": "Blanc" },
    { "name": "blue", "displayName": "Bleu" },
    { "name": "bordeaux", "displayName": "Bordeaux" },
    { "name": "grey", "displayName": "Gris" },
    { "name": "yellow", "displayName": "Jaune" },
    { "name": "brown", "displayName": "Marron" },
    { "name": "black", "displayName": "Noir" },
    { "name": "golden", "displayName": "Or" },
    { "name": "orange", "displayName": "Orange" },
    { "name": "red", "displayName": "Rouge" },
    { "name": "green", "displayName": "Vert" },
    { "name": "violet", "displayName": "Violet" }
  ]
}

const reducer = (state, { type, payload }) => {
  switch(type) {
    case 'SET_CAMERAS':
      return { ...state, cameras: payload.cameras || [] }
    case 'SET_CAR_MAKES':
      return { ...state, carMakes: payload.makes || [] }
    case 'SET_CAR_MODELS':
      return { ...state, carModels: payload.models || [] }
    default:
      throw new Error()
  }
}

const context = createContext(initialState);
const { Provider } = context;

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = {
    fetchCameras: () =>
      axios.get('/api/cameras')
        .then(({ data }) => {
          dispatch({ type: 'SET_CAMERAS', payload: data })
          return data
        }),
    fetchCarMakes: () =>
      axios.get('/api/cars/makes')
        .then(({ data }) => {
          dispatch({ type: 'SET_CAR_MAKES', payload: data })
          return data
        }),
    fetchCarModels: (make) =>
      axios.get(`/api/cars/makes/${make}/models`)
        .then(({ data }) => {
          dispatch({ type: 'SET_CAR_MODELS', payload: data })
          return data
        })
  }

  return <Provider value={[state, actions]}>{children}</Provider>
}

export default context
