import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'
import axios from 'axios'
import qs from 'qs'

import SearchContext from '../contexts/SearchContext'
import * as services from '../services'

import { Tabs } from '../components'
import { CenterContainer, Container } from '../components/Grid'
import { PageTitle, Error } from '../components/Typography'
import { SearchSection } from '../components/SearchSection'
import { Checkbox, EditIcon } from '../components/design-system'
import { SmallButton, BigButton } from '../components/Button'
import { Select, TextInput } from '../components/Input'
import HistoryTable from '../components/HistoryTable'

import SearchCriteriaSection from '../components/SearchCriteriaSection'
import SearchCamerasSection from '../components/SearchCamerasSection'
import PlateSearch from '../components/PlateSearch'

const Description = styled(CenterContainer)`
  margin-top: 0;
  text-align: center;
`

const NameInputContainer = styled(CenterContainer)`
  width: 32%;
  margin: auto;
  flex-direction: column;

  form > *:not(:first-child) {
    margin-top: 1rem;
  }
`

const StyledSearchSection = styled(SearchSection)`
  ${({ displayCameras }) => displayCameras && 'border: none !important;'}
  padding-bottom: ${({ displayCameras }) => displayCameras ? '0' : '2rem'} !important;
`

const NotificationTypeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }
`

const TabContent = styled(Container)`
  margin-top 2rem;
  width: 90% !important;
`

const PlateSearchContainer = styled.div`
  margin-top: 1.5rem;
`

const Standard = ({ form, setForm, selectTag, selectModel, selectColor, selectMake }) => (
  <SearchCriteriaSection
    selected={form.tag}
    selectTag={selectTag}
    selectModel={selectModel}
    selectColor={selectColor}
    selectMake={selectMake}
    form={form}
    setForm={setForm}
  />
)

const Alert = ({ history }) => {
  const [
    { tags, cameras, carMakes, carColors, carModels },
    { fetchCameras, fetchCarMakes, fetchCarModels }
  ] = useContext(SearchContext)

  const [allSectors, setAllSectors] = useState(null)
  const [currentTab, setCurrentTab] = useState('standard')
  const [frequency, setFrenquency] = useState('real-time')
  const [vpiSearch, setVpiSearch] = useState([])
  const [standardSearch, setStrandardSearch] = useState([])
  // const [allSearch, setAllSearch] = useState([])
  const [displayCameras, setDisplayCameras] = useState(false)
  const [researchName, setResearchName] = useState('')
  const [submitError, setSubmitError] = useState(null)
  const [newPlate, setNewPlate] = useState('')
  const [plates, setPlates] = useState([])
  const [plateError, setPlateError] = useState(null)
  const [form, setForm] = useState({
    cameras: [],
    notifications: ['app'],
    tag: 'car',
    since: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000 - 1 * 60 * 60 * 1000), // -1year -1h in ms
    until: new Date()
  })

  useEffect(() => {
    fetchCameras()
      .then(({ cameras }) => setForm({ ...form, cameras: cameras.map(({ id }) => id) }))
    fetchCarMakes()
    services.fetchAllSectors({})
      .then(({ requests }) => {
        setAllSectors(requests)
      })
  }, []);

  useEffect(() => {
    services.fetchAllVPISearch().then(({ requests }) => setVpiSearch(
      requests.splice().map(e => ({
        ...e,
        type: 'VPI',
        search: e.plates.split(',').join(', '),
        // date: strftime('%d/%m/%Y', new Date(Number(e.at))),
        // hour: strftime('%H:%M', new Date(Number(e.at)))
      })
      )))
  }, [])

  useEffect(() => {
    services.fetchAllStandardSearch().then(({ requests }) => {
      const formattedRequests = requests.slice().map((e, i) => {
        if (i >= 10) return null
        let search = ''
        const searchType = tags.filter(tag => tag.name === e.tag)[0].displayName
        const gender = {
          female: 'Femme',
          male: 'Homme',
          girl: 'Fille',
          boy: 'Garçon',
        }
        const age = {
          adult: 'Adulte (+18)',
          infant: 'Enfant',
        }
        const clothingColor = {
          black: 'Noir',
          blue: 'Bleu',
          gray: 'Gris',
          green: 'Vert',
          red: 'Rouge',
          white: 'Blanc',
          yellow: 'Jaune',
        }
        const getGender = (gender, age) => {
          if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
          return (age === 'infant' ? 'Garçon' : 'Homme')
        }

        if (e.tag === 'car' || e.tag === 'heavyVehicles' || e.tag === 'twoWheeled') {
          search += carMakes.filter(brand => brand.name === e.make)[0]?.displayName || e.make || ''
          search += ' '
          search += carModels.filter(model => model.name === e.model)[0]?.displayName || e.model || ''
          search += ' '
          search += carColors.filter(color => color.name === e.color)[0]?.displayName || e.color || ''
        }

        if (e.tag === 'person') {
          search += gender[e?.gender]
            ? `${getGender(e.gender, e.age)} ` : ''
          search += age[e?.age]
            ? `${age[e?.age]} ` : ''
          search += clothingColor[e?.top_color]
            ? `Haut ${clothingColor[e?.top_color]}` : ''
          search += clothingColor[e?.bottom_color]
            ? ` Bas ${clothingColor[e?.bottom_color]}` : ''
        }

        return ({
          ...e,
          type: searchType,
          date: strftime('%d/%m/%Y', new Date(Number(e.at))),
          hour: strftime('%H:%M', new Date(Number(e.at))),
          search: search
        })
      })

      setStrandardSearch(formattedRequests)
    })
  }, []);

  // useEffect(() => {
  //   const concatSearch = [
  //     ...standardSearch,
  //     ...vpiSearch,
  //   ]

  //   setAllSearch(
  //     concatSearch
  //       .sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)))
  //   )
  // }, [standardSearch, vpiSearch])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (researchName === '') {
      setSubmitError('Veuillez entrer un nom pour votre recherche.')
      return
    }

    let params = {
      name: researchName,
      cameras: form.cameras.join(','),
      since: form.since.getTime(),
      until: form.until.getTime(),
      notifications: form.notifications.join(','),
    }
    const vehiculeParams = {
      make: form?.make,
      model: form?.model,
      color: form?.color,
    }
    const personParams = {
      age: form?.age,
      gender: form?.gender,
      topColor: form?.topColor,
      bottomColor: form?.bottomColor,
    }
    const vpiParams = {
      plates: plates.map(p => p.toUpperCase()).join(','),
    }

    if (currentTab !== 'vpi') {
      params = {
        ...params,
        tag: form.tag,
      }
    }
    if (form?.tag === 'car' && currentTab === 'standard') {
      params = {
        ...params,
        ...vehiculeParams,
      }
    }
    if (form?.tag === 'person' && currentTab === 'standard') {
      params = {
        ...params,
        ...personParams,
      }
    }
    if (currentTab === 'vpi') {
      params = {
        ...params,
        ...vpiParams,
      }
    }

    setSubmitError(null)
    history.push('/sequences?' + qs.stringify(params))
  }

  const toggleDisplayCameras = () => setDisplayCameras(!displayCameras)

  const selectTag = (event) => setForm({ ...form, tag: event.target.value, make: null, model: null })
  const selectMake = value => {
    fetchCarModels(value)
    setForm({ ...form, make: value })
  }
  const selectModel = value => setForm({ ...form, model: value })
  const selectColor = value => setForm({ ...form, color: value })
  const selectNotificationType = (value, type) => {
    const include = form.notifications.includes(type)

    if (value && !include) {
      setForm({
        ...form,
        notifications: [...form.notifications, type]
      })
    }
    if (!value && include) {
      setForm({
        ...form,
        notifications: form.notifications.filter(e => e !== type)
      })
    }
  }

  const handleSetResearchName = value => {
    const regexTest = /(^[a-zA-Z0-9\_\-]+$)|^$/g.test(value);

    if (regexTest) setResearchName(value)
  }

  const handleChange = value => {
    const regexTest = /(^[a-zA-Z0-9\*]+$)|^$/g.test(value);

    if (regexTest) setNewPlate(value.toUpperCase())
  }
  const addPlate = (event) => {
    event.preventDefault()

    if (newPlate === '') {
      setPlateError('Veuillez entrer un numéro de plaque d\'immatriculation.')
      return
    }
    if (plates.filter(plate => plate === newPlate).length != 0) {
      setPlateError('Numéro de plaque d\'immatriculation déjà entré.')
      return
    }
    setPlates([...plates, newPlate])
    setNewPlate('')
    setPlateError(null)
  }

  // const tableHeader = [
  //   {
  //     name: 'Lancée le',
  //     sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
  //   },
  //   {
  //     name: 'Nom',
  //     sort: list => list.slice().sort((a, b) => {
  //       if (a?.name < b?.name) return -1
  //       if (a?.name > b?.name) return 1
  //       return 0
  //     }),
  //   },
  //   {
  //     name: 'Périmètre',
  //     sort: list => list.slice().sort((a, b) => {
  //       const lengthA = (a?.cameras || '').split(',').length
  //       const lengthB = (b?.cameras || '').split(',').length
  //       return lengthA - lengthB
  //     }),
  //   },
  //   { name: 'Type' },
  //   // { name: 'Recherche' },
  //   { name: 'Status' },
  //   { name: 'Action' },
  // ]

  const panes = [
    {
      key: 'standard',
      title: 'Standard',
      content: (
        <TabContent>
          <Standard
            form={form}
            setForm={setForm}
            selectTag={selectTag}
            selectModel={selectModel}
            selectColor={selectColor}
            selectMake={selectMake}
          />
        </TabContent>
      ),
    },
    {
      key: 'vpi',
      title: 'VPI',
      content: (
        <TabContent>
          <strong>Indiquez les plaques</strong> à rechercher. <strong>Si vous ne disposez que d’une partie de la plaque</strong>,
          veillez à positionner les numéros dont vous disposez à leur place exacte et inscrivez
          « * » à la place des autres. Par exemple pour une plaque française à 7 caractères,
          si vous ne disposez que du premier caractère « A » et des quatrième et cinquième caractères « 1 »
          et « 2 », renseignez « A**12** ».
          <PlateSearchContainer>
            <PlateSearch
              plates={plates}
              setPlates={setPlates}
              addPlate={addPlate}
              newPlate={newPlate}
              handleChange={handleChange}
              plateError={plateError}
            />
          </PlateSearchContainer>
        </TabContent>
      ),
    },
  ]

  return (
    <>
      <PageTitle>Alertes</PageTitle>
      <Description>
        <span>Les alertes fonctionnent sur le même principe qu’une recherche <strong>mais en temps réel</strong>.</span>
        Chaque correspondance avec les critères de recherche vous sera notifié dans la barre de navigation
      </Description>
      <StyledSearchSection
        displayCameras={displayCameras}
        title='Périmètre'
        icon='/images/camera.png'
        description={<><strong>Selectionner les 4 caméras</strong> (maximum).</>}
      >
        <SmallButton type="button" onClick={toggleDisplayCameras} primary>
          {displayCameras ? 'Masquer les caméras' : 'Afficher les caméras'}
        </SmallButton>
      </StyledSearchSection>
      <SearchCamerasSection
        camerasList={cameras}
        sectors={allSectors}
        selectedCameras={form.cameras}
        setSelectedCameras={v => setForm({ ...form, cameras: v })}
        toggle={displayCameras}
      />
      <SearchSection
        title='Critères'
        icon='/images/filters.svg'
        description={<><strong>Indiquez sur quoi</strong> porte la recherche.</>}
      >
        <Tabs
          currentTab={currentTab}
          panes={panes}
          onChange={v => setCurrentTab(v)}
        />
      </SearchSection>
      {/* <SearchSection
        title='Critères'
        icon='/images/desklist.png'
        description={<><strong>Indiquez sur quoi</strong> porte la recherche.</>}
      >
        <SearchCriteriaSection
          selected={form.tag}
          selectTag={selectTag}
          selectModel={selectModel}
          selectColor={selectColor}
          selectMake={selectMake}
          form={form}
          setForm={setForm}
        />
      </SearchSection> */}
      <SearchSection>
        <NotificationTypeContainer>
          <b>Notifications :</b>
          <Checkbox
            checked={form.notifications.includes('app')}
            onChange={v => selectNotificationType(v, 'app')}
            label='Application'
            inline
          />
          <Checkbox
            checked={form.notifications.includes('email')}
            onChange={v => selectNotificationType(v, 'email')}
            label='Email'
            inline
          />
          <Checkbox
            checked={form.notifications.includes('sms')}
            onChange={v => selectNotificationType(v, 'sms')}
            label='SMS'
            inline
          />
        </NotificationTypeContainer>
        <NameInputContainer>
          <form onSubmit={handleSubmit}>
            <Select
              label='Fréquences des notifications'
              name='frequency'
              value={frequency}
              onChange={setFrenquency}
            >
              <option value='real-time'>En temps réel (défaut)</option>
            </Select>
            <TextInput
              label='Nommer la recherche'
              icon={EditIcon}
              value={researchName}
              onChange={handleSetResearchName}
            />
            {
              submitError && <Error>{submitError}</Error>
            }
            <BigButton disabled type="submit">
              Lancer l'alerte
            </BigButton>
          </form>
        </NameInputContainer>
      </SearchSection>
      {/* <SearchSection title='Recherches récentes' icon='/images/task.svg'>
        <HistoryTable
          limit={10}
          body={allSearch}
          header={tableHeader}
        />
      </SearchSection> */}
    </>
  )
}

export default Alert
