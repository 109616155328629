

import React from 'react'
import styled from 'styled-components'

import SequenceLoader from '../containers/SequenceLoader'

import { H1, H3, ColoredLink, Link } from '../components/Typography'
import SequenceThumbnail from '../components/SequenceThumbnail'

const FootagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: ${({ rowNb }) => `repeat(${rowNb}, 1fr)`};
  row-gap: 1.5rem;
  column-gap: 1rem;
`

const Footages = ({ loading, sequences, displaySequence, paging }) => {
  const rowNb = sequences.length / 4

  return (
    <>
      <FootagesContainer rowNb={rowNb}>
        {
          sequences.map(({ url, score, ...props }, i) => (
            <SequenceLoader
              key={`sequence-${i}-${props.trackId}`}
              fromUrl={`/api/detections/${props.configId}/${props.trackId}`}
            >
              <SequenceThumbnail
                {...props}
                showControls={false}
                playOnHover={true}
                onClick={displaySequence}
                confidence={score}
              />
            </SequenceLoader>
          ))
        }
      </FootagesContainer>
      {
        !loading && !sequences.length && (
          <p style={{ textAlign: 'center' }}>
            Aucun résultat. Effectuer une <Link to="/search">nouvelle recherche</Link> ?
          </p>
        )
      }
      {
        loading && (
          <p style={{ textAlign: 'center' }}>
            Veuillez patienter, récupération des données en cours...
          </p>
        )
      }
    </>
  )
}

export default Footages
