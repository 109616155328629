import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { CenterContainer } from '../components/Grid'
import { SearchIcon, EditIcon } from '../components/design-system'
import { H1, H3 } from '../components/Typography'

const StyledCenterContainer = styled(CenterContainer)`
  flex-direction: row;
`

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: .8rem;
`

// TODO: Hidden input under H3 to have width fit string length
const InputH3 = styled.input`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 15px;
  font-family: Blinker;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  ${({ readOnly }) => readOnly ? 'border: none;' : ''}

  &:focus {
    ${({ readOnly }) => readOnly ? 'outline: none;' : ''}
  }
`

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  height: 1.3rem;
  width: 1.3rem;
  margin-left: .6rem;
  color: ${({ theme }) => theme.primaryColor};
`

const StyledH1 = styled(H1)`
  margin: 0;
`

/*
** Confidence
*/


const ConfidenceContainer = styled(CenterContainer)`
  flex-direction: row;
  margin-top: 0;
`

const ConfidenceRating = styled.div`
  color: ${({ confidence }) => {
    if (confidence === 'high') return '#92DE42'
    if (confidence === 'medium') return '#FF9F00'
    if (confidence === 'low') return '#F61A1A'
    return '#000'
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5rem;

  ${H3} {
    color: #000;
  }
`

const Confidence = ({ stats }) => {
  return (
    <ConfidenceContainer>
      <ConfidenceRating confidence='high'>
        <div>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <H3>{stats.nbDetectsRank3}</H3>
      </ConfidenceRating>
      <ConfidenceRating confidence='medium'>
        <div>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
        </div>
        <H3>{stats.nbDetectsRank2}</H3>
      </ConfidenceRating>
      <ConfidenceRating confidence='low'>
        <div><FontAwesomeIcon icon={faStar} /></div>
        <H3>{stats.nbDetectsRank1}</H3>
      </ConfidenceRating>
    </ConfidenceContainer>
  )
}

const SequencesHeader = ({ editName, editNameValue, setEditName, handleSearchName, stats }) => {
  return (
    <>
      {/*<StyledCenterContainer>*/}
        {/* <StyledSearchIcon /> */}
        {/* <form
          onSubmit={e => {
            e.preventDefault()
            setEditName(!editName)
          }}
        >
          <InputH3
            readOnly={editName}
            value={editNameValue}
            onChange={handleSearchName}
          />
        </form> */}
        <H3>{editNameValue}</H3>
        {/* <StyledEditIcon onClick={() => setEditName(!editName)} /> */}
      {/*</StyledCenterContainer>*/}
      <StyledH1>Résultats</StyledH1>
      <Confidence stats={stats} />
    </>
  )
}

export default SequencesHeader
