import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'

import SequenceLoader from '../containers/SequenceLoader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons'
import { CF, Row, Hr } from '../components/Grid'
import { H1, P, Link } from '../components/Typography'
import PlateThumbnail from '../components/PlateThumbnail'
import PlatesFilters from '../components/PlatesFilters'
import ProgressBar from '../components/ProgressBar'
import { Modal } from '../components/Modal'
import Pagination from '../components/Pagination'
import SequencePlayer from '../components/SequencePlayer'

const Section = styled.section``
const Container = styled(CF)``
const MargedContainer = styled(Container)`
  margin: 15px;
`
const Headings = styled.div`
  margin: 30px 0;
`
const Plate = styled.p`
  display: inline-block;
  margin: 10px 0.5%;
  padding: 8px 10px;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
`
const ButtonLink = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  color: #74ccf8;
  text-decoration: none;
  background-color: transparent;

  &:focus { outline: none; }
`

const fetchRequest = ({ id }) => axios.get(`/api/anpr/requests/${id}`).then(({ data }) => data)
const fetchDetections = ({ id }) => axios.get(`/api/anpr/requests/${id}/detections`).then(({ data }) => data)
const putPlateFavorite = ({ requestId, trackId, ...params }) =>
  axios.put(`/api/anpr/requests/${requestId}/detections/${trackId}/favorite`, params).then(({ data }) => data)
const putPlateHidden = ({ requestId, trackId, ...params }) =>
  axios.put(`/api/anpr/requests/${requestId}/detections/${trackId}/hide`, params).then(({ data }) => data)

const PlateDetectionsContainer = ({
  plate,
  detections,
  onClickThumbnail,
  onClickFavorite,
  onClickHide,
}) => {
  const itemsCountPerPage = 16
  const pageRangeDisplayed = 5

  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)

  const handlePageChange = index => {
    setPage(index)
    setOffset((index-1) * itemsCountPerPage)
  }

  return (
    <Container>
      <Row style={{ display: 'flex' }}>
        <Plate>{plate}</Plate>
        <Hr style={{ flex: 1 }} />
      </Row>
      <Row>
        {detections.slice(offset).slice(0, itemsCountPerPage).map(({ requestId, trackId, ...props }, i) =>
          <SequenceLoader key={`plate-${i}-${trackId}`} fromUrl={`/api/plates/${requestId}/${trackId}/sequence`}>
            <PlateThumbnail
              showControls={false}
              onClickFavorite={() => onClickFavorite(plate, trackId)}
              onClickHide={() => onClickHide(plate, trackId)}
              onClick={onClickThumbnail}
              trackId={trackId}
              requestId={requestId}
              {...props}
            />
          </SequenceLoader>
        )}
      </Row>
      {
        detections.length > itemsCountPerPage &&
        <Pagination
          activePage={page}
          totalItemsCount={detections.length}
          itemsCountPerPage={itemsCountPerPage}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={handlePageChange}
        />
      }
    </Container>
  )
}

const Plates = () => {
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [plates, setPlates] = useState({})

  const [onlyHidden, setOnlyHidden] = useState(false)
  const [onlyFavorites, setOnlyFavorites] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)
  const [currentPlate, setCurrentPlate] = useState(null)

  useEffect(() => {
    let _t

    const refetch = async () => {
      const { request } = await fetchRequest(params)

      setProgress(request.progress)
      if (request.status == 'done') {
        setLoading(true)

        const { plates } = await fetchDetections({ id: request.id })

        setPlates(plates)
        setTimeout(() => setLoading(false), 500)
        return
      }
      _t = setTimeout(refetch, 1000)
    }

    refresh()
    _t = setTimeout(refetch, 1000)
    return () => _t && clearTimeout(_t)
  }, [])

  const refresh = async () => {
    setLoading(true)

    const { request } = await fetchRequest(params)
    const { plates } = await fetchDetections({ id: request.id })

    setProgress(request.progress)
    setPlates(plates)

    setTimeout(() => setLoading(false), 500)
  }

  const toggleFavorite = (p, trackId) => {
    const { id: requestId } = params
    const i = plates[p].detections.findIndex(r => r.trackId == trackId)
    const favorite = !plates[p].detections[i].favorite

    putPlateFavorite({ requestId, trackId, favorite }).then(_ => {
      plates[p].detections[i].favorite = favorite
      setPlates({...plates}) // force update
    })
  }

  const toggleHidden = (p, trackId) => {
    const { id: requestId } = params
    const i = plates[p].detections.findIndex(r => r.trackId == trackId)
    const hidden = !plates[p].detections[i].hidden

    putPlateHidden({ requestId, trackId, hidden }).then(_ => {
      plates[p].detections[i].hidden = hidden
      setPlates({...plates}) // force update
    })
  }

  const handleFiltersChange = (favorites, hidden) => {
    setOnlyFavorites(favorites)
    setOnlyHidden(hidden)
  }

  const toggleModal = () =>
    setModalOpen(!modalOpen)

  const displayPlate = plate => {
    setCurrentPlate(plate)
    setModalOpen(true)
  }

  return (
    <div>
      <ProgressBar value={progress} hideAt={100} />
      <Section>
        <MargedContainer>
          <Link to="/vpi">VPI</Link>
          <span>{' >'} Résultats</span>
        </MargedContainer>
      </Section>
      <Headings>
        <H1 style={{ margin: '0' }}>Recherche</H1>
        {progress < 100 &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <P style={{ textAlign: 'center', margin: '0', fontSize: '13px' }}>
              Traitement des videos en cours ({progress}%)
              {' - '}
              <ButtonLink onClick={refresh}>
                <FontAwesomeIcon icon={loading ? faSpinner : faDownload} spin={loading} style={{ marginRight: '5px' }} />
                {!loading && 'Rafraichir'}
              </ButtonLink>
            </P>
          </div>
        }
      </Headings>
      <Section>
        <MargedContainer style={{ display: 'flex', alignItems: 'right'}}>
          {Object.keys(plates).length > 0 &&
            <PlatesFilters
              onlyFavorites={onlyFavorites}
              onlyHidden={onlyHidden}
              onChange={handleFiltersChange}
            />
          }
        </MargedContainer>
        {Object.keys(plates).map(p =>
          <PlateDetectionsContainer
            key={`plates-${p}`}
            plate={p}
            detections={
              onlyHidden ?    plates[p].detections.filter(q => q.hidden) :
              onlyFavorites ? plates[p].detections.filter(q => q.favorite) :
                              plates[p].detections.filter(q => !q.hidden)
            }
            onClickHide={toggleHidden}
            onClickFavorite={toggleFavorite}
            onClickThumbnail={displayPlate}
          />
        )}
        {
          !loading && (progress == 100) && !Object.keys(plates).length && (
            <P style={{ textAlign: 'center', margin: '25px 0' }}>
              Aucun résultat. Effectuer une <Link to="/anpr">nouvelle recherche</Link> ?
            </P>
          )
        }
      </Section>
      <Modal
        isOpen={modalOpen}
        onCloseClick={toggleModal}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}>
          {currentPlate &&
            <SequencePlayer
              videos={currentPlate.videos}
              showControls={true}
              playOnHover={false}
              preload="auto"
              pauseAtFirstDetection={true}
            />
          }
      </Modal>
    </div>
  )
}

export default Plates
