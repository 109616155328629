import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import SearchContext from '../contexts/SearchContext'

const StyledInfos = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`
const InfoLi = styled.li``
const StyledInfo = styled.strong`
  font-weight: bold;
`
const StyledCamera = styled.span`
  background-color: #6ada4a;
  border: 1px solid #6ada4a;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  display: inline-block;
  font-family: Blinker;
  font-size: 13px;
  font-weight: 600;
  height: 26px;
  letter-spacing: 1px;
  line-height: 26px;
  margin: 5px 0 5px 10px;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
`
const StyledSlash = styled.span`
  color: #74ccf8;
`

export default ({ params, ...props }) => {
  const [
    { cameras, tags, carMakes, carModels, carColors },
    { fetchCameras, fetchCarMakes, fetchCarModels }
  ] = useContext(SearchContext)

  useEffect(() => {
    // Load context if empty
    if (!cameras.length) fetchCameras()
    if (!carMakes.length) fetchCarMakes()
    if (!carModels.length) fetchCarModels(params.make)
  }, [])

  const formatCamera = id => {
    const camera = !!cameras.length && cameras.find(c => c.id === id*1)
    return camera ? <StyledCamera key={`camera-${id}`}>{camera.id} - {camera.name}</StyledCamera> : null
  }

  const formatDate = date =>
    strftime("%d/%m/%Y %H:%M", new Date(date*1))

  const _cameras = (params.cameras || '').split(',')
  const tag = tags.find(t => t.name === params.tag)
  const make = carMakes.find(m => m.name === params.make)
  const model = carModels.find(m => m.name === params.model)
  const color = carColors.find(c => c.name === params.color)

  return (
    <StyledInfos {...props}>
      <InfoLi>
        Périmètre: {_cameras.slice(0, 5).map(formatCamera)}
        {_cameras.length > 5 && <StyledCamera>...</StyledCamera>}
      </InfoLi>
      <InfoLi>Du <StyledInfo>{params.since ? formatDate(params.since) : '-'}</StyledInfo> au <StyledInfo>{params.until ? formatDate(params.until) : '-'}</StyledInfo></InfoLi>
      <InfoLi>
        Type: <StyledInfo>{!!tag && tag.displayName}</StyledInfo>
        {!!make && <span> <StyledSlash>/</StyledSlash> Marque: <StyledInfo>{make.displayName}</StyledInfo></span>}
        {!!model && <span> <StyledSlash>/</StyledSlash> Modèle: <StyledInfo>{model.displayName}</StyledInfo></span>}
        {!!color && <span> <StyledSlash>/</StyledSlash> Couleur: <StyledInfo>{color.displayName}</StyledInfo></span>}
      </InfoLi>
    </StyledInfos>
  )
}
