

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import SearchContext from '../contexts/SearchContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Select } from './Input'

const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: start;
  margin-bottom: 1rem;

  & > * {
    margin: 0 .5rem;
  }

  & > *:first-child {
    margin: 0 .5rem 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 .5rem;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
`

// const Space = styled.div`
//   flex-grow: .8;
// `

// const FiltersCheckboxesContainer = styled(Container)`
//   display: flex;
//   align-items: center;

//   & > *:not(:first-child) {
//     margin-left: 2rem;
//   }

//   width: auto !important;
//   margin: 0;
// `

const Filters = ({
  params,
  paging,
  handleFiltersChange,
  tag,
  searchCameras
}) => {
  const [{ cameras }, { fetchCameras }] = useContext(SearchContext)
  const [loading, setLoading] = useState(true)
  const handleChange = (value, type) => {
    handleFiltersChange(
      1,
      type === 'sortType' ? value : paging.sortType,
      type === 'sortBy' ? value : paging.sortBy,
      type === 'cameras' ? value : paging.cameras,
      type === 'filter' ? value : paging.filter,
    )
  }

  const filterCameras = cameras.filter(
    camera => searchCameras.includes(camera.id.toString())
  )

  useEffect(() => {
    fetchCameras().then(() => setLoading(false))
  }, [])

  return (
    <FiltersContainer>
      <Select
        label='Trier par'
        value={paging.sortBy}
        onChange={v => handleChange(v, 'sortBy')}
      >
        <option value='score:desc'>Du score le plus haut au plus bas</option>
        <option value='score:asc'>Du score le plus bas au plus haut</option>
        <option value='at:desc'>Du plus récent au plus ancien</option>
        <option value='at:asc'>Du plus ancien au plus récent</option>
      </Select>
        {
          tag === 'car' && (params.make || params.color) &&
          <Select
            label='Score basé sur'
            value={paging.groupBy}
            onChange={v => handleChange(v, 'sortType')}
          >
            {
              params.make && params.color && <option value='score:quality'>Modèle / Couleur</option>
            }
            {
              params.make && <option value='score:make'>Modèle</option>
            }
            {
              params.color && <option value='score:color'>Couleur</option>
            }
          </Select>
        }
        {/* {
          tag === 'person' &&
          <>
            <option value='confidence'>Tout</option>
            <option value='gender_confidence'>Genre</option>
            <option value='age_confidence'>Âge</option>
            <option value='top_confidence'>Couleur vêtements haut</option>
            <option value='bottom_confidence'>Couleur vêtements bas</option>
          </>
        } */}
      {/*
      <Space />
      <FiltersCheckboxesContainer>
        <b>Afficher :</b>
        {
          zones.map((zone, i) => (
            <Checkbox
              // TODO: filters zones
              // checked={true}
              key={`zone-${zone.name}-${i}`}
              label={zone.name}
              inline
            />
          ))
        }
        <Checkbox
          checked={paging.favorite}
          onChange={v => setFilters({ ...filters, favorite: v })}
          label='Favoris'
          inline
        />
        <Checkbox
          checked={paging.nonFavorite}
          onChange={v => setFilters({ ...filters, nonFavorite: v })}
          label='Non-Favoris'
          inline
        />
      </FiltersCheckboxesContainer>
      */}
      {
        loading 
        ? (
          <LoadingContainer>
            <FontAwesomeIcon
              icon={loading ? faSpinner : faDownload}
              spin={loading}
              style={{ marginRight: '5px' }}
            />
          </LoadingContainer>
        ) : (
          <Select
            label='Filtrer par caméra'
            labelOverflow={false}
            value={!paging.cameras || paging.cameras.length <= 0 ? 'all' : paging.cameras[0]}
            onChange={v => handleChange([ v ], 'cameras')}
          >
            <option
              key='all'
              value={[]}
            >
              Toutes
            </option>
            {
              filterCameras.map((camera, i) => (
                <option
                  key={'camera-' + i}
                  value={[ camera.id ]}
                >
                  {camera.name}
                </option>
              ))
            }
          </Select>
        )
      }
      <Select
        label='Filtrer par type'
        labelOverflow={false}
        value={paging.filter}
        onChange={v => handleChange(v, 'filter')}
      >
        <option
          key='all'
          value='all'
        >
          Toutes les séquences
        </option>
        <option
          key='favorite'
          value='favorite'
        >
          Favorites
        </option>
        <option
          key='hidden'
          value='hidden'
        >
          Cachées
        </option>
      </Select>
    </FiltersContainer>
  )
}

export default Filters
