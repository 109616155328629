import axios from 'axios'

export const fetchAllSectors = params =>
  axios
    .get(`/api/sectors/requests`, { params })
    .then(({ data }) => data)


export const addSector = params =>
  axios
    .post(`/api/sectors/requests`, params)
    .then(({ data }) => data)

export const deleteSector = id =>
  axios
    .delete(`/api/sectors/${id}`, {data: {}})

export const updateSector = (id, cameras) => {
  console.log(id, cameras)
  return axios
    .put(`/api/sectors/${id}`, cameras)
    .then(({ data }) => data)
}
