
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { EditIcon, Checkbox } from './design-system'
import { TextInput, TextInputMultiline, ValidationTextInput } from './Input'
import { BigButton } from '../components/Button'
import { ColoredLink, Error } from '../components/Typography'

const EditCameraContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > div:nth-child(1) {
    flex-grow: 0.6;
  }
  & > div:nth-child(2) {
    flex-grow: 0.4;
    padding: 0 1rem;

    & > *:last-child {
      width: fit-content;
      margin-left: 0;
    }

    & > *:not(:first-child) {
      margin-top: 1rem;
    }
  }

  button {
    width: min-content;
    margin-left: 0;
  }
`

const Video = styled.div`
  margin-top: 1rem;
  background-color: #000;
  height: 87%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CheckboxContainer = styled.div`
  & > *:not(:first-child) {
    margin-left: 2rem;
  }
`

const FieldIcon = styled(FontAwesomeIcon)``

const CameraError = styled(Error)`
  margin-bottom: 1rem;
`
let cancelTest = () => { }
const testCamera = params => axios.post('/api/cameras/test', params, {
  cancelToken: new axios.CancelToken(c => cancelTest = c)
}).then(({ data }) => data)


const EditCamera = ({
  createCamera,
  creating,
  onSubmitTest,
  selectedCamera,
  setSelectedCamera,
  setUriToTest,
  fetchCameras,
  setCreating,
  testing,
  testingError,
  uriToTest,
  updateCamera,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const handleCancel = () => setSelectedCamera(null)

  useEffect(() => setError(null), [selectedCamera])

  const handleSubmit = () => {
    if (!selectedCamera.name) {
      setError('Veuillez donner un nom à la caméra.')
      return
    }
    if (process.env.NODE_ENV === 'staging') return

    setError(null)
    setSubmitting(true)
    if (creating) {
      createCamera(selectedCamera).then(() => {
        fetchCameras()
        handleCancel()
        setCreating(false)
        setSubmitting(false)
      })
    } else {
      updateCamera(selectedCamera).then(() => {
        fetchCameras()
        handleCancel()
        setSubmitting(false)
      })
    }
  }

  if (!selectedCamera) {
    return (
      <>
        {
          error && <CameraError>{error}</CameraError>
        }
        {
          testingError && <CameraError>{testingError}</CameraError>
        }
        <form onSubmit={onSubmitTest}>
          <ValidationTextInput
            placeholder='rtsp://'
            onChange={v => selectedCamera
              ? setSelectedCamera({ ...selectedCamera, input: v })
              : setUriToTest(v)
            }
            value={selectedCamera?.input || uriToTest}
            button={testing
              ? <FieldIcon onClick={() => testCamera({ input: selectedCamera?.input || uriToTest})} icon={faSpinner} spin color='#fff' />
              : 'tester'
            }
            onSubmit={onSubmitTest}
          />
        </form>
      </>
    )
  }

  return (
    <>
      <EditCameraContainer>
        <div>
          <form onSubmit={onSubmitTest}>
            <ValidationTextInput
              placeholder='rtsp://'
              onChange={v => selectedCamera
                ? setSelectedCamera({ ...selectedCamera, input: v })
                : setUriToTest(v)
              }
              value={selectedCamera?.input || uriToTest}
              button={testing
                ? <FieldIcon icon={faSpinner} spin color='#fff' />
                : 'tester'
              }
              onSubmit={onSubmitTest}
              valid={true}
              disabled={true}
            />
          </form>
          <Video>
            <FieldIcon color='#fff' icon={faSpinner} spin />
          </Video>
        </div>
        <div>
          <TextInput
            label='Nom'
            icon={EditIcon}
            value={selectedCamera?.name}
            onChange={v => setSelectedCamera({ ...selectedCamera, name: v })}
          />
          <TextInput
            label='Coordonées GPS'
            placeholder='Lat,Long'
            onChange={v => setSelectedCamera({ ...selectedCamera, coordinates: v })}
            icon={EditIcon}
            value={''}
          />
          <TextInputMultiline
            label='Description (100 car. max)'
            onChange={v => setSelectedCamera({ ...selectedCamera, description: v })}
            icon={EditIcon}
            value={selectedCamera?.description || ''}
            rows={5}
            maxlength={100}
          />
          {/* <Select
            label='Ajouter un secteur existant'
            value={''}
            onChange={v => {}}
          >
            <option></option>
          </Select> */}
          <CheckboxContainer>
            <Checkbox
              inline
              checked={selectedCamera?.saveVideo}
              onChange={v => setSelectedCamera({ ...selectedCamera, saveVideo: v })}
              label='Archives vidéos'
            />
            <Checkbox
              inline
              checked={selectedCamera?.detect}
              onChange={v => setSelectedCamera({ ...selectedCamera, detect: v })}
              label='Détections'
            />
          </CheckboxContainer>
          <BigButton spin={submitting} onClick={handleSubmit}>Valider</BigButton>
          <ColoredLink onClick={handleCancel}>Annuler</ColoredLink>
        </div>
      </EditCameraContainer>
    </>
  )
}

export default EditCamera
