import React, { useState } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faStar, faHeart, faEye, faLowVision } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'

import SequencePlayer from './SequencePlayer'

const StyledPlate = styled.div`
  position: relative;
  margin: 10px 0.5%;
  background: #000;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  overflow: hidden;
  float: left;
  box-sizing: border-box;
  display: inline-block;

  width: 24%;
  height: 0;
  padding-top: calc(9 / 16 * 23%);
`

const PlateInfos = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  text-align: right;

  ${props => props.top && 'top: 0'}
  ${props => props.bottom && 'bottom: 0'}
`

const SequenceLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${PlateInfos} {
    display: none;
  }
`

const PlateInfo = styled.li`
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  margin: 2px;
  padding: 2px 5px;
  display: inline-block;
  background: #000;
  background: rgba(0,0,0,.8);
  color: #fff;
  border-radius: 4px;

  ${props => props.left && `
    float: left;
    font-size: 10px;
    line-height: 20px;
  `}
`

const Options = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const Option = styled.button`
  border: none;
  cursor: pointer;
  color: #74ccf8;
  text-decoration: none;
  background: #000;
  background: rgba(0,0,0,.8);
  font-size: 16px;
  padding: 4px 5px 2px;
  margin-left: 5px;
  border-radius: 4px;

  &:focus { outline: none; }
`

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  background: #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = () =>
  <StyledLoader>
    <FontAwesomeIcon icon={faSpinner} spin />
  </StyledLoader>

export default ({
  cameraId,
  requestId,
  trackId,
  videos,
  score,
  at,
  favorite,
  hidden,
  onClick = () => null,
  onClickFavorite = () => null,
  onClickHide = () => null,
}) => {
  const [loading, setLoading] = useState(true)

  return (
    <StyledPlate>
      <SequenceLink href="#" onClick={() => onClick({ cameraId, at, videos })}>
        <SequencePlayer
          poster={'/api/plates/' + requestId + '/' + trackId + `/poster?width=720`}
          videos={videos}
          showControls={false}
          playOnHover={false}
          pauseAtFirstDetection={false}
          onCanPlay={() => setLoading(false)}
        />
        {!loading &&
          <React.Fragment>
            <PlateInfos top>
              {score >= 25 &&
                <PlateInfo left>
                  <FontAwesomeIcon icon={faStar} />
                  {score >= 50 && <FontAwesomeIcon icon={faStar} />}
                  {score >= 75 && <FontAwesomeIcon icon={faStar} />}
                </PlateInfo>
              }
            </PlateInfos>
            <PlateInfos bottom>
              <PlateInfo>Camera #{cameraId}</PlateInfo>
              <PlateInfo>{strftime("%d/%m/%Y %H:%M:%S", new Date(at * 1))}</PlateInfo>
            </PlateInfos>
            <Options>
              {!favorite &&
                <Option onClick={e => { e.preventDefault(); e.stopPropagation(); onClickHide() }}>
                  {hidden ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faLowVision} />}
                </Option>
              }
              {!hidden &&
                <Option onClick={e => { e.preventDefault(); e.stopPropagation(); onClickFavorite() }}>
                  {favorite ? <FontAwesomeIcon icon={faHeart} /> : <FontAwesomeIcon icon={farHeart} />}
                </Option>
              }
            </Options>
          </React.Fragment>
        }
      </SequenceLink>
      {loading && <Loader />}
    </StyledPlate>
  )
}
