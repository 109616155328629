import React from 'react'
import styled from 'styled-components'

import { H3 } from '../components/Typography'
import { DateTimePicker } from '../components/Input'
import { AngleRightIcon } from '../components/design-system'
import { CriteriaWrapper } from '../components/SearchSection'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min-content;
  margin-top: 0;
  align-self: self-end;
`

const StyledAngleRightIcon = styled(AngleRightIcon)`
  height: 3rem;
  width: auto;
  justify-self: center;
`

const SearchDateSection = ({ until, since, setDate }) => (
  <CriteriaWrapper columns='1fr auto 1fr' style={{ textAlign: 'center' }}>
    <div>
      <H3>Du :</H3>
      <DateTimePicker
        max={until}
        value={since}
        onChange={v => setDate('since', v)}
      />
    </div>
    <IconContainer>
      <StyledAngleRightIcon />
    </IconContainer>
    <div>
      <H3>Au :</H3>
      <DateTimePicker
        min={since}
        value={until}
        onChange={v => setDate('until', v)}
      />
    </div>
  </CriteriaWrapper>
)

export default SearchDateSection
