import React from 'react';
import styled from "styled-components";

const StyledCameraTooltip = styled.div`
  display: none;
  width: 120px;
  background-color: #0f1215;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding-top: 1rem;
  width: 300px;
  position: absolute;
  z-index: 2;
  top: 115%;
  left: calc(50% - 150px);
  font-size: .875rem;
  box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.3);

  div:first-child {
    padding: 0 1rem;
    padding-bottom: 1rem;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: calc(50% - 10px);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const CameraTooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${StyledCameraTooltip} {
      display: block;
    }
  }

  ${StyledCameraTooltip}:hover {
    visibility: visible;
  }
`;

const CameraTooltipButton = styled.button`
  width: 100%;
  text-align: center;
  padding: .9rem 0;
  color: ${({ theme, disabled }) => disabled ? '#5a6269' : theme.primaryColor};
  background-color: transparent;
  border: none;
  border-top: 1px solid #fff;
  cursor: ${({ disabled }) => disabled ? 'inherit' : 'pointer'};

  &:last-child {
    color: ${({ theme }) => theme.errorRed};
  }
`

export const CameraTooltip = ({
  camera,
  children,
  deleteCamera,
  modifyCamera,
  ...props
}) => {
  return (
    <CameraTooltipContainer {...props}>
      {children}
      <StyledCameraTooltip>
        <div>
          <b>{camera.input}</b><br />
          {/* Secteur: {camera.sector.join(', ')} */}
          <b>Archives videos:</b> {camera.saveVideo ? 'Oui' : 'Non'}<br />
          <b>Détections:</b> {camera.detect ? 'Oui' : 'Non'}<br />
          <b>Description:</b> {camera.description ? camera.description : <i>Pas de description disponible.</i>}<br />
        </div>
        <CameraTooltipButton onClick={() => modifyCamera(camera)}>
          Consulter en temps réel / Modifier
        </CameraTooltipButton>
        {/* <CameraTooltipButton>
          Retirer du secteur
        </CameraTooltipButton> */}
        <CameraTooltipButton onClick={() => deleteCamera(camera)}>
          Supprimer
        </CameraTooltipButton>
      </StyledCameraTooltip>
    </CameraTooltipContainer>
  );
};
