import React, { useState, useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components';
import styled from 'styled-components'
import strftime from 'strftime'

import * as services from '../services'

import SearchContext from '../contexts/SearchContext'

import { Checkbox, H1, CrossIcon, Icon, SearchInput, Pagination } from '../components/design-system'
import HistoryTable from '../components/HistoryTable'
import { Select } from '../components/Input'

const StyledH1 = styled(H1)`
  font-family: Blinker;
  margin-top: .5rem;
  margin-bottom: 2.5rem;
`
const Edit = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: ${({ toggle }) => toggle ? '0' : '-64px'};
  opacity: ${({ toggle }) => toggle ? '1' : '0'};
  left: 0;
  width: 100%;
  align-items: center;
  height: 200px;
  box-shadow: 0px -5px 18px 5px rgb(0 0 0 / 20%);
  transition: bottom 0.5s ease, opacity 0.5s ease-in;
`;

const EditContent = styled.div`
  margin: auto;
  width: 70%;

  h2 {
    font-family: Blinker;
  }
`

const ColoredLink = styled.span`
  color: ${({ color }) => color};
  text-decoration: underline;
  cursor: pointer;
  position absolute;
  top: 1.3rem;
  right: 1.5rem;

  ${Icon} {
    margin-left: .3rem;
    vertical-align: middle;
  }
`

const StyledCheckbox = styled(Checkbox)`
  label {
    color: ${({ color }) => color};
    height: 1000px;
  }
`

const Camera = styled.div`
  background-color: ${({ activated }) => activated ? '#999999' : 'transparent'};
  color: ${({ activated }) => (activated ? '#fff' : '#999999')};
  border: 2px solid #999999;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.25rem;
  height: 2rem;
  width: 6rem;
`;

const StyledSearchInput = styled(SearchInput)`
  flex-grow: 1;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;

  & > *:not(:first-child):not(:last-child) {
    margin: 0 1rem;
  }
`

const SelectContainer = styled.div`
  width: 30%;
`

const Space = styled.div`
  flex-grow: .8;
`

export default () => {
  const themeContext = useContext(ThemeContext)
  const [
    { tags, carMakes, carColors, carModels }
  ] = useContext(SearchContext)

  const [filters, setFilters] = useState({
    standard: true,
    vpi: true,
  })
  const [vpiSearch, setVpiSearch] = useState([])
  const [standardSearch, setStrandardSearch] = useState([])
  const [allSearch, setAllSearch] = useState([])
  const [selected, setSelected] = useState(null)
  const [nbRows, setNbRows] = useState(20)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)

  const itemsCountPerPage = 16
  const pageRangeDisplayed = 5
  const tableHeader = [
    {
      name: 'Date',
      sort: list => list.slice().sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at))),
    },
    {
      name: 'Nom',
      sort: list => list.slice().sort((a, b) => {
        if (a?.name < b?.name) return -1
        if (a?.name > b?.name) return 1
        return 0
      }),
    },
    {
      name: 'Périmètre',
      sort: list => list.slice().sort((a, b) => {
        const lengthA = (a?.cameras || '').split(',').length
        const lengthB = (b?.cameras || '').split(',').length
        return lengthA - lengthB
      }),
    },
    {
      name: 'Type',
      // sort: () => {},
    },
    // { name: 'Recherche' },
    { name: 'Status' },
    { name: 'Action' },
  ]

  useEffect(() => {
    services.fetchAllVPISearch().then(({ requests }) => setVpiSearch(
      requests.slice().map(e => ({
        ...e,
        type: 'VPI',
        search: e.plates.split(',').join(', '),
        date: strftime('%d/%m/%Y', new Date(Number(e.at))),
        hour: strftime('%H:%M', new Date(Number(e.at)))
      })
      )))
  }, [])

  useEffect(() => {
    services.fetchAllStandardSearch().then(({ requests }) => {
      const formattedRequests = requests.slice().map((e, i) => {
        if (i >= 10) return null
        let search = ''
        const searchType = tags.filter(tag => tag.name === e.tag)[0].displayName
        const gender = {
          female: 'Femme',
          male: 'Homme',
          girl: 'Fille',
          boy: 'Garçon',
        }
        const age = {
          adult: 'Adulte (+18)',
          infant: 'Enfant',
        }
        const clothingColor = {
          black: 'Noir',
          blue: 'Bleu',
          gray: 'Gris',
          green: 'Vert',
          red: 'Rouge',
          white: 'Blanc',
          yellow: 'Jaune',
        }
        const getGender = (gender, age) => {
          if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
          return (age === 'infant' ? 'Garçon' : 'Homme')
        }

        if (e.tag === 'car' || e.tag === 'heavyVehicles' || e.tag === 'twoWheeled') {
          search += carMakes.filter(brand => brand.name === e.make)[0]?.displayName || e.make || ''
          search += ' '
          search += carModels.filter(model => model.name === e.model)[0]?.displayName || e.model || ''
          search += ' '
          search += carColors.filter(color => color.name === e.color)[0]?.displayName || e.color || ''
        }

        if (e.tag === 'person') {
          search += gender[e?.gender]
            ? `${getGender(e.gender, e.age)} ` : ''
          search += age[e?.age]
            ? `${age[e?.age]} ` : ''
          search += clothingColor[e?.top_color]
            ? `Haut ${clothingColor[e?.top_color]}` : ''
          search += clothingColor[e?.bottom_color]
            ? ` Bas ${clothingColor[e?.bottom_color]}` : ''
        }

        return ({
          ...e,
          type: searchType,
          date: strftime('%d/%m/%Y', new Date(Number(e.at))),
          hour: strftime('%H:%M', new Date(Number(e.at))),
          search: search
        })
      })

      setStrandardSearch(formattedRequests)
    })
  }, []);

  useEffect(() => {
    const concatSearch = [
      ...standardSearch,
      ...vpiSearch,
    ]

    setAllSearch(
      concatSearch
        .sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)))
    )
  }, [standardSearch, vpiSearch])

  const handlePageChange = index => {
    setPage(index)
    setOffset((index - 1) * itemsCountPerPage)
  }

  const handleSearch = value => {
    const concatSearch = [
      ...standardSearch,
      ...vpiSearch,
    ].sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)))
    const filteredSearch = concatSearch.filter(row => row?.name.search(value) !== -1)

    setAllSearch(filteredSearch)
  }

  const changeFilter = (filter, value) => {
    const newFilters = {
      ...filters,
      [filter]: value,
    }

    if (newFilters.vpi && newFilters.standard) {
      const concatSearch = [
        ...standardSearch,
        ...vpiSearch,
      ]

      setAllSearch(
        concatSearch
          .sort((a, b) => new Date(Number(a?.at)) - new Date(Number(b?.at)))
      )
    }
    else if (!newFilters.vpi && newFilters.standard) setAllSearch(standardSearch)
    else if (newFilters.vpi && !newFilters.standard) setAllSearch(vpiSearch)
    else setAllSearch([])

    setFilters(newFilters)
  }

  return (
    <>
      <StyledH1>Historique de recherche</StyledH1>
      <Filters>
        <StyledCheckbox
          label='Recherche standard'
          labelColor='#144E95'
          checked={filters.standard}
          onChange={v => changeFilter('standard', v)}
          inline
        />
        <StyledCheckbox
          label='Recherche VPI'
          labelColor='#CF6B46'
          checked={filters.vpi}
          onChange={v => changeFilter('vpi', v)}
          inline
        />
        <StyledSearchInput
          onChange={v => handleSearch(v)}
          placeholder='Rechercher par nom...'
        />
        <Space />
        <SelectContainer>
          <Select value={nbRows} onChange={v => setNbRows(Number(v))}>
            <option value="20" >Afficher 20 lignes</option>
            <option value="50">Afficher 50 lignes</option>
            <option value="100">Afficher 100 lignes</option>
          </Select>
        </SelectContainer>
      </Filters>
      <HistoryTable
        limit={nbRows}
        body={allSearch}
        header={tableHeader}
        selected={selected}
        setSelected={setSelected}
      />
      {/* <Pagination
        activePage={page}
        totalItems={100}
        itemsCountPerPage={itemsCountPerPage}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      /> */}
      {/* <Edit toggle={!!selected}>
        <ColoredLink
          color={themeContext.errorRed}
          onClick={() => setSelected(null)}
        >
          Fermer le volet <CrossIcon />
        </ColoredLink>
        <EditContent>
          <h2>Périmètre:</h2>
          <Camera activated>LH1Z1-2</Camera>
          <Camera>LH1Z1-2</Camera>
        </EditContent>
      </Edit> */}
    </>
  )
}
