import React, { Children, useState, useEffect, useContext, forwardRef } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

import { Icon, CheckIcon, CrossIcon, SearchIcon } from './Icons'
import { Dropdown, DropdownItem } from './Dropdown'

export const Form = styled.form``

export const Label = styled.label`
  display: inline-block;
  font-family: Roboto;
  font-size: .875rem;
`

const InputStyle = css`
  padding: 1rem;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  &:focus {
    outline: 0;
  }

  ${({ dark }) => `
    color: ${dark ? '#fff' : '#000'};
    background-color: ${dark ? '#000' : '#fff'};

    &::placeholder {
      color: ${dark ? '#aaa' : '#777'};
    }

    &:disabled {
      color: ${dark ? '#555' : '#777'};
      background-color: ${dark ? '#000' : '#fafafa'};

      &::placeholder {
        color: ${dark ? '#555' : '#777'};
      }
    }
  `}
`

InputStyle.propTypes = {
  dark: PropTypes.bool
}

const StyledInput = styled.input`
  ${InputStyle}
`

StyledInput.propTypes = InputStyle.propTypes

export const Select = ({
  placeholder,
  children,
  onChange,
  ...props
}) => {
  const [selected, setSelected] = useState(null)

  const getSelected = () =>
    Array.isArray(children) ? children.find(child => child && child.props.selected) : children

  useEffect(() => {
    setSelected(getSelected())
  }, [children])

  const handleClick = child => {
    if (!selected || child.props.value != selected.props.value)
      onChange(child.props.value)
    setSelected(child)
  }

  return (
    <Dropdown {...props}
      toggler={(selected && selected.props.value && selected.props.children) || placeholder}
      onhold={!selected || !selected.props.value}
    >
      {Children.map(children, (child, i) =>
        child && React.cloneElement(child, {
          key: `option-${i}`,
          selected: selected && child.props.value === selected.props.value,
          onClick: handleClick.bind(child, child)
        })
      )}
    </Dropdown>
  )
}

Select.propTypes = {
  ...Dropdown.propTypes,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func
}

Select.defaultProps = {
  placeholder: null,
  onChange: () => null
}

export const Option = styled(DropdownItem)``

const StyledCheckbox = styled.label`
  display: ${({ inline }) => inline ? 'inline-flex' : 'flex'};
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: baseline;

  input { display: none; }

  i {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.checkbox.borderColor};
    border-radius: 5px;
    line-height: 1;
    color: ${({ theme }) => theme.primaryColor};
    vertical-align: text-top;
  }

  input + i ${Icon} { display: none; }
  input:checked + i ${Icon} { display: inline-block; }
  input:checked + i + span { font-weight: bold; }
  input:disabled + i + span {
    color: #777;
    pointer-events: none;
  }

  span {
    margin-left: .5rem;
    font-size: 0.875rem;
    color: ${({ error, theme, labelColor }) => {
      if (error) return theme.errorRed
      if (labelColor) return labelColor
      return theme.black
    }};
    ${({ bold }) => bold && 'font-weight: bold !important;'}
  }
`

StyledCheckbox.propTypes = {
  labelColor: PropTypes.string,
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
    checkbox: PropTypes.shape({
      borderColor: PropTypes.string
    })
  })
}

export const Checkbox = ({ label, checked, onChange, error, labelColor, inline, bold, ...props }) => {
  const handleChange = e => onChange(e.target.checked)

  return (
    <StyledCheckbox error={error} labelColor={labelColor} inline={inline} bold={bold}>
      <StyledInput {...props} checked={checked} onChange={handleChange} type="checkbox" />
      <i><CheckIcon /></i>
      {label && <span>{label}</span>}
    </StyledCheckbox>
  )
}

Checkbox.propTypes = {
  ...StyledInput.propTypes,
  onChange: PropTypes.func,
  label: PropTypes.string,
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  error: PropTypes.bool,
  labelColor: PropTypes.string,
}

Checkbox.defaultProps = {
  onChange: () => null,
  error: false,
  inline: false,
  bold: false,
}

const StyledRadio = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: baseline;

  input {
    display: none;
  }
  span {
    margin-left: .5rem;
    font-size: 0.875rem;
  }
  input + i {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid #ddd;
    border-radius: 1rem;
    line-height: 1;
    vertical-align: text-top;
  }
  input + i::before,
  input + i::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content:'';
    border-radius: 1rem;
  }
  input + i::before {
    bottom: 0;
    left: 0;
    background-color: transparent;
    width: 1rem;
    height: 1rem;
  }
  input + i::after {
    bottom: .25rem;
    left: .25rem;
    width: .5rem;
    height: .5rem;
  }
  input:checked + i::after {
    background-color: ${({ theme }) => theme.primaryColor};
  }
  input:checked + i + span { font-weight: bold; }
  input:disabled + i + span {
    color: #777;
    pointer-events: none;
  }
`

StyledRadio.propTypes = {
  theme: PropTypes.shape({
    primaryColor: PropTypes.string
  })
}

export const Radio = ({ label, onChange, ...props }) => {
  const handleChange = e => onChange(e.target.value)

  return (
    <StyledRadio className={props.className}>
      <StyledInput {...props} onChange={handleChange} type="radio" />
      <i></i>
      {label && <span>{label}</span>}
    </StyledRadio>
  )
}

Radio.propTypes = {
  ...StyledInput.propTypes,
  onChange: PropTypes.func,
  label: PropTypes.string
}

Radio.defaultProps = {
  onChange: () => null
}

const SearchInputContainer = styled.div`
  display: inline-flex;
  align-items: center;

  input {
    flex: 1;
    background: transparent;
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.input.borderColor};
    border-radius: 0;
    transition: border-bottom-color 0.25s ease-out;
    padding: .5rem;
    margin-right: .625rem;
    color: inherit;
    &:focus {
      outline: none;
      border-bottom-color: ${({ theme }) => theme.primaryColor};
    }
    &::placeholder {
      color: ${({ theme }) => theme.label.color};
    }
  }
  ${Icon} {
    vertical-align: middle;
  }
`

SearchInputContainer.propTypes = {
  theme: PropTypes.shape({
    borderColor: PropTypes.string,
    primaryColor: PropTypes.string,
    inputLabelColor: PropTypes.string
  })
}

export const SearchInput = ({ disabled, onChange, className, ...props }) => {
  const themeContext = useContext(ThemeContext)

  const handleChange = e => onChange(e.target.value)

  return (
    <SearchInputContainer className={className}>
      <StyledInput
        disabled={disabled}
        {...props}
        onChange={handleChange}
      />
      <SearchIcon color={disabled ? themeContext.input.disabled.color : themeContext.primaryColor} />
    </SearchInputContainer>
  )
}

SearchInput.propTypes = {
  ...StyledInput.propTypes,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

SearchInput.defaultProps = {
  placeholder: null,
  onChange: () => null
}

const StyledTextInputContainer = styled.span`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  border: 1px solid ${({ dark, theme }) => dark ? theme.input.dark.borderColor : theme.input.borderColor};
  border-radius: 5px;
  background-color: ${({ disabled, theme }) => disabled ? theme.input.disabled.backgroundColor : theme.input.backgroundColor};
  padding: .938rem .313rem .938rem 0;
  input, textarea {
    width: 100%;
    z-index: ${({ disabled }) => disabled ? '0' : '998'};
    font-size: .875rem;
    color: ${({ dark, theme }) => dark ? theme.input.dark.color : 'initial'};
    background-color: ${({ disabled, theme }) => disabled ? theme.input.disabled.backgroundColor : theme.input.backgroundColor};
    border: none;
    padding-left: 1.125rem;
    padding-right: ${({ icon }) => icon === 'true' ? '0' : '.87rem'};
    padding-top: ${({ icon }) => icon === 'true' ? '0' : '.125rem'};
    padding-bottom: ${({ icon }) => icon === 'true' ? '0' : '.125rem'};
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: Saira;
    }
  }
  ${Icon} {
    margin: auto;
    padding: 0 10px 0 10px;
    width: 20px;
    height: 20px;
  }
`

StyledTextInputContainer.propTypes = {
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    input: PropTypes.shape({
      borderColor: PropTypes.string,
      backgroundColor: PropTypes.string,
      disabled: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    })
  })
}

const StyledTextInputButton = styled.button.attrs({
  type: 'button',
})`
  padding: 0;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
    ${Icon} {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
  &:focus {
    outline: none;
  }
  ${Icon} {
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0 .625rem 0 .625rem;
  }
`

StyledTextInputButton.propTypes = {
  theme: PropTypes.shape({
    primaryHover: PropTypes.string
  })
}

const StyledTextInputLabel = styled.span`
  user-select: none;
  color: ${({ dark, error, theme }) => {
    if (error) return 'white'
    if (dark) return theme.label.dark.color
    return theme.label.color
  }};
  background-color: ${({ dark, value, error, focus, disabled, theme }) => {
    if (error) return theme.errorRed
    if (disabled) return theme.input.disabled.backgroundColor
    if (!focus && value === '') return 'transparent'
    if (dark) return theme.label.dark.backgroundColor
    return theme.label.backgroundColor
  }};
  font-weight: ${({ error }) => error ? 'bold' : 'normal'};
  position: absolute;
  padding: 0 0.75rem;
  left: 0.4rem;
  font-family: ${({ error }) => error ? 'Roboto' : 'Saira'};
  transition: font-size 0.25s ease;
  transition: margin-top 0.25s ease-out;
  top: ${({ focus, value, error }) => (focus || value !== '') || error ? '0' : 'auto'};
  margin-top: ${({ focus, value, error }) => (focus || value !== '') || error ? '-.688rem' : 'initial'};
  font-size: ${({ focus, value, error }) => (focus || value !== '') || error ? '.75rem' : '.875rem'};
`

StyledTextInputLabel.propTypes = {
  error: PropTypes.bool,
  focus: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    input: PropTypes.shape({
      backgroundColor: PropTypes.string,
      labelColor: PropTypes.string,
      label: PropTypes.shape({
        backgroundColor: PropTypes.string
      })
    })
  })
}

export const TextInput = forwardRef(({
  buttonIcon,
  dark,
  disabled,
  label,
  onChange,
  onClick,
  required,
  validator,
  value,
  inputAs,
  ...props
}, ref) => {
  const IconButton = buttonIcon
  const themeContext = useContext(ThemeContext)
  const [inputValue, setInputValue] = useState(!!value ? value : '')
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setInputValue(!!value ? value : '')
  }, [value])

  const checkError = valueInput => {
    if (validator && !required) return !valueInput ? null : validator(valueInput)
    if (validator && required) return !valueInput ? 'Obligatoire' : validator(valueInput)
    if (required) return !valueInput ? 'Obligatoire' : null
    return null
  }

  const handleBlur = () => {
    const _error = checkError(inputValue)

    setFocus(false)
    setError(_error)
  }

  const handleChange = e => {
    const _error = checkError(e.target.value)

    setInputValue(e.target.value)
    onChange(e.target.value)
    setError(_error)
  }

  const iconType = () => {
    if (buttonIcon) {
      return (
        <StyledTextInputButton onClick={e => onClick(e, inputValue)} disabled={disabled}>
          <IconButton color={disabled ? themeContext.inputBorder : themeContext.primaryColor} />
        </StyledTextInputButton>
      )
    }
    if (required || validator) {
      return (
        <>
          {error && (
            <CrossIcon style={{ color: themeContext.errorRed }} />
          )}
          <CheckIcon
            style={{
              visibility: (inputValue !== '') ? 'visible' : 'hidden',
              display: error ? 'none' : 'block',
              color: themeContext.validGreen
            }}
          />
        </>
      )
    }
    return null
  }

  const icon = iconType()

  return (
    <StyledTextInputContainer dark={dark} disabled={disabled} icon={(!!icon).toString()}>
      <StyledInput
        ref={ref}
        {...props}
        as={inputAs}
        required={required}
        value={inputValue}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <StyledTextInputLabel
        dark={dark}
        className='text-input-label'
        label={label}
        disabled={disabled}
        error={!!error}
        value={inputValue}
        focus={focus}
      >
        {error ? error : label}
        {required && !error ? <span style={{ color: themeContext.errorRed }}>*</span> : null}
      </StyledTextInputLabel>
      {icon}
    </StyledTextInputContainer>
  )
})

TextInput.propTypes = {
  ...StyledInput.propTypes,
  buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  inputAs: PropTypes.string,
}

TextInput.defaultProps = {
  dark: false,
  inputAs: 'input',
  onChange: () => null,
  onClick: () => null,
}

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  ${({ inline }) => inline && `
    flex-direction: row;

    ${StyledRadio} + ${StyledRadio},
    ${StyledCheckbox} + ${StyledCheckbox} {
      margin-left: 1.5rem;
    }
  `}
`

FormGroup.propTypes = {
  inline: PropTypes.bool,
}

FormGroup.defaultProps = {
  inline: true,
}
