import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import strftime from 'strftime'

import { Icon, AngleDownIcon, AngleUpIcon } from '../components'
import { Link } from '../components/Typography'
import { SpinnerAnimatedIcon } from '../components/design-system/Icons'

import SearchContext from '../contexts/SearchContext'

import * as services from '../services'

const StyledTable = styled.table`
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  text-align: left;

  td,
  th {
    border: 1px solid #e5e5e5;
    padding: .35rem .8rem;
  }

  th {
    text-transform: uppercase;
  }
`;

const StyledTr = styled.tr`
  width: 100%;

  td {
    color: ${({ selected, isSelected }) => {
      if (isSelected) return '#000;'
      if (!!selected) return '#999;'
      return '#000;'
    }}
    transition: color .4s ease-in-out;
  }

  ${Icon} {
    color: ${({ theme }) => theme.primaryColor};
    vertical-align: middle;
    cursor: pointer;
    margin-left: .5rem;
    display: ${({ selected, isSelected }) => {
      if (isSelected) return 'inline;'
      if (!!selected) return 'none;'
      return 'inline;'
    }}
  }
`

const TDCenter = styled.td`
  text-align: center;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Table = ({ type, selected, header, limit }) => {
  const [
    { tags, carMakes, carColors, carModels }
  ] = useContext(SearchContext)

  const [sortedBody, setSortedBody] = useState([])
  const [requests, setRequests] = useState([])
  const [sortList, setSortList] = useState(header.map(() => 'desc'))
  const [onStatusUpdate, setOnStatusUpdate] = useState(true)

  useEffect(() => {
    if (type === 'VPI')
      services
      .fetchAllVPISearch()
      .then(({ requests }) => {
        const body = requests.slice().map(e => {

          return {
            ...e,
            type: "Plaque d'immatriculation",
            sinceDate: strftime('%d/%m/%Y', new Date(Number(e.since))),
            sinceHour: strftime('%H:%M', new Date(Number(e.since))),
            untilDate: strftime('%d/%m/%Y', new Date(Number(e.until))),  untilHour: strftime('%H:%M', new Date(Number(e.until)))
          }
        })

        setSortedBody(body)
        setRequests(requests)
      })
    else if (type === 'Search')
      services
        .fetchAllStandardSearch({ limit: 100 })
        .then(({ requests }) => {
          const body = requests.slice().map(e => {
            let search = ''
            const searchType = tags.filter(tag => tag.name === e.tag)[0].displayName
            const gender = {
              female: 'Femme',
              male: 'Homme',
              girl: 'Fille',
              boy: 'Garçon',
            }
            const age = {
              adult: 'Adulte (+18)',
              infant: 'Enfant',
            }
            const clothingColor = {
              black: 'Noir',
              blue: 'Bleu',
              gray: 'Gris',
              green: 'Vert',
              red: 'Rouge',
              white: 'Blanc',
              yellow: 'Jaune',
            }
            const getGender = (gender, age) => {
              if (gender === 'female') return age === 'infant' ? 'Fille' : 'Femme'
              return (age === 'infant' ? 'Garçon' : 'Homme')
            }

            if (e.tag === 'car' || e.tag === 'heavyVehicles' || e.tag === 'twoWheeled') {
              search += carMakes.filter(brand => brand.name === e.make)[0]?.displayName || e.make || ''
              search += ' '
              search += carModels.filter(model => model.name === e.model)[0]?.displayName || e.model || ''
              search += ' '
              search += carColors.filter(color => color.name === e.color)[0]?.displayName || e.color || ''
            }

            if (e.tag === 'person') {
              search += gender[e?.gender]
                ? `${getGender(e.gender, e.age)} ` : ''
              search += age[e?.age]
                ? `${age[e?.age]} ` : ''
              search += clothingColor[e?.top_color]
                ? `Haut ${clothingColor[e?.top_color]}` : ''
              search += clothingColor[e?.bottom_color]
                ? ` Bas ${clothingColor[e?.bottom_color]}` : ''
            }

            return ({
              ...e,
              type: searchType,
              date: strftime('%d/%m/%Y', new Date(Number(e.at))),
              name: e.name,
              sinceDate: strftime('%d/%m/%Y', new Date(Number(e.since))),
              sinceHour: strftime('%H:%M', new Date(Number(e.since))),
              untilDate: strftime('%d/%m/%Y', new Date(Number(e.until))),  untilHour: strftime('%H:%M', new Date(Number(e.until))),
              hour: strftime('%H:%M', new Date(Number(e.at))),
              search: search.trim()
            })
          })

          setSortedBody(body)
          setRequests(requests)
        })
  }, [])

  useEffect(() => {
    if (type === 'Search') {
      const intervalId = setInterval(function () {
        Promise
          .all(requests.map(
            request => services.fetchStandardSearch(request.id)
          ))
          .then(all => {
            const newSortedBody = sortedBody.map(elem => {
              const v = all.find(({ request }) => request.id === elem.id)
  
              if (v) {
                return {
                  ...elem,
                  progress: v.request.progress
                }
              }
  
              return {
                ...elem,
                progress: 0.0
              }
            })
  
            setSortedBody(newSortedBody)
          }).finally(() => setOnStatusUpdate(false))
      }, 5000)
  
      return () => clearInterval(intervalId)
    } else if (type === 'VPI') {
      const intervalId = setInterval(function () {
        const newSortedBody = sortedBody.map(elem => {
          return {
            ...elem,
            progress: 100
          }
        })

        setOnStatusUpdate(false)
        setSortedBody(newSortedBody)
      }, 20000)

      return () => clearInterval(intervalId)
    }
  })

  const handleSort = (sortFunction, index) => {
    if (!sortedBody) return

    const sortTmp = [...sortList]

    if (sortList[index] === 'asc') {
      sortTmp[index] = 'desc'

      setSortedBody(sortFunction(sortedBody))
      setSortList(sortTmp)
    }
    if (sortList[index] === 'desc') {
      sortTmp[index] = 'asc'

      setSortedBody(sortFunction(sortedBody).reverse())
      setSortList(sortTmp)
    }
  }

  return (
    <StyledTable>
      <thead>
        <StyledTr>
          {
            header.map((e, i) => (
              <th key={`${e}-${i}`}>
                <Flex>
                  {e.name}
                  {e.sort && sortList[i] === 'desc' && <AngleDownIcon onClick={() => handleSort(e.sort, i)} />}
                  {e.sort && sortList[i] === 'asc' && <AngleUpIcon onClick={() => handleSort(e.sort, i)} />}
                </Flex>
              </th>
            ))
          }
        </StyledTr>
      </thead>
      <tbody>
        {
          sortedBody.map((row, i) => {
            let colorDisplay

            if (row === null || i >= limit) return
            if (
              colorDisplay = carColors.find(({ name }) => name === row.color)
            ) {
              colorDisplay = colorDisplay.displayName
            }
            return (
              <StyledTr key={`${row.name}-${i}`} isSelected={selected?.name === row.name} selected={selected}>
                <TDCenter>
                  {strftime('%d/%m/%Y', new Date(Number(row.at)))}
                  <br />
                  {strftime('%H:%M', new Date(Number(row.at)))}
                </TDCenter>
                <td>{row.name}</td>
                <TDCenter>
                  {row.sinceDate}
                  <br />
                  {row.sinceHour}
                </TDCenter>
                <TDCenter>
                  {row.untilDate}
                  <br />
                  {row.untilHour}
                </TDCenter>
                <td>
                  <Flex>
                    {(row.cameras || '').split(',').length} cameras
                    {/* ${row.zones} zones`} */}
                    {/* <EditIcon isSelected={selected?.name === row.name} onClick={() => setSelected(row)} /> */}
                  </Flex>
                </td>
                <td>
                  {row.type}
                  {row.type && ' '}
                  {row.make && row.make.charAt(0).toUpperCase() + row.make.slice(1)}
                  {row.make && ' '}
                  {row.model && row.model.charAt(0).toUpperCase() + row.model.slice(1)}
                  {row.model && ' '}
                  {colorDisplay && colorDisplay.charAt(0).toUpperCase() + colorDisplay.slice(1)}
                  {colorDisplay && ' '}
                  {row.gender && row.gender.charAt(0).toUpperCase() + row.gender.slice(1)}
                  {row.gender && ' '}
                  {row.top_color && row.top_color.charAt(0).toUpperCase() + row.top_color.slice(1)}
                  {row.bottom_color && ' '}
                </td>
                {
                  onStatusUpdate
                    ? <TDCenter><SpinnerAnimatedIcon /></TDCenter>
                    : (
                      <td>
                        {Math.ceil(row.progress) === 100.0 || row.progress === 100
                        ? 'Terminé'
                        : Math.ceil(row.progress) + '%'}
                      </td>
                    )
                }
                <td>
                  <Link
                    to={
                      (type === 'VPI' || !type)
                        ? `/vpi/${row.id}`
                        : `/search/${row.id}`
                    }
                  >
                    Résultats
                  </Link>
                </td>
              </StyledTr>
            )
          })
        }
      </tbody>
    </StyledTable>
  )
}

Table.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      sort: PropTypes.func,
    })
  ).isRequired,
  limit: PropTypes.number,
}

export default Table
