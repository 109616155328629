import React from 'react'
import styled from 'styled-components'

import { CenterContainer } from './Grid'
import { TextInput } from './Input'
import { ColoredLink } from './Typography'

const SectorInputContainer = styled(CenterContainer)`
  width: 30%;
  margin: auto;
  flex-direction: column;
`

const SectorsContainer = styled(CenterContainer)`
  padding-top: 1.5rem;
  border-top: 1px solid #E5E5E5;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 1rem;
  min-width: 80vw;
  flex-wrap: wrap;
`

const SectorNameContainer = styled.div`
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
  border-radius: 5px;
  padding: .2rem .6rem;
`

const SectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${SectorNameContainer} {
    ${({ hasCameras }) => !hasCameras && 'border-color: #E5E5E5;'}
  }

  & > *:not(:first-child) {
    font-size: .688rem;
  }
  & > *:nth-child(n+3) {
    text-decoration: underline;
  }
  & > *:first-child {
    ${({ hasCameras }) => !hasCameras && 'color: #999999;'}
  }
  & > *:nth-child(2) {
    color: #595959;
    margin: .3rem 0;
    ${({ hasCameras }) => !hasCameras && 'color: #999999;'}
  }
`

const SectorCreation = ({
  allSectors,
  newSector,
  setNewSector,
  onSubmitSector,
  addButton,
  setSectorToDelete,
}) => {
  const AddButton = addButton

  return (
    <SectorInputContainer>
      <form onSubmit={onSubmitSector}>
        <TextInput
          label='Créer nouveau secteur'
          icon={AddButton}
          value={newSector}
          onChange={v => setNewSector(v)}
        />
      </form>
      <SectorsContainer>
        {
          allSectors &&
          allSectors.map((sector, i) => {
            return (
              <SectorContainer
                key={`${sector.name}-${i}`}
                hasCameras={!!sector?.cameras}
              >
                <SectorNameContainer>
                  {sector.name}
                </SectorNameContainer>
                <div>
                  {
                    sector?.cameras
                      ? sector?.cameras.split(',').length
                      : 0
                  }
                  {
                    sector?.cameras && sector?.cameras.split(',').length > 1
                      ? ' caméras'
                      : ' caméra'
                  }
                </div>
                {/* <ColoredLink>Renommer</ColoredLink> */}
                <ColoredLink
                  color='#F61A1A'
                  onClick={() => setSectorToDelete(sector)}
                >
                  Supprimer
                </ColoredLink>
              </SectorContainer>
            )
          })
        }
      </SectorsContainer>
    </SectorInputContainer>
  )
}

export default SectorCreation
