import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

// import Me from './Me'
import History from './History'
import User from './User'
// import Users from './Users'
import { Tabs } from '../components'

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 1.875rem 0;
`;

export default (props) => {
  const history = useHistory()
  const { resource } = useParams()
  const panes = [
    {
      key: 'me',
      title: 'Informations personnelles',
      content: <User {...props} />,
    },
    {
      key: 'history',
      title: 'Historique de recherche',
      content: <History {...props} />,
    },
  ]

  return (
    <Container>
      <Tabs
        currentTab={resource}
        panes={panes}
        onChange={v => history.push(`/account/${v}`)}
      />
      </Container>
    )
}
