import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Checkbox, CheckIcon } from './design-system'
import { Error } from '../components/Typography'
import { CameraTooltip } from './Tooltip'
import { Container, CenterContainer } from './Grid'
import { SpinnerAnimatedIcon } from '../components/design-system/Icons'

const SearchCamerasSectionContainer = styled.div`
  height: ${({ toggle, standalone }) => toggle || standalone ? 'auto' : '0'};
  padding: ${({ toggle, standalone }) => toggle && !standalone ? '2rem 0' : '0'};
  opacity: ${({ toggle, standalone }) => toggle || standalone ? '1' : '0'};
  ${({ toggle }) => toggle && 'margin-top: 2rem;'}
  transition: opacity .6s ease-in-out, padding .6s ease-in-out;
  overflow: ${({ standalone }) => standalone ? 'inherit' : 'hidden'};
  -moz-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  -webkit-box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  box-shadow: inset 0px -13px 17px -12px rgba(0,0,0,0.2), inset 0px 13px 17px -12px rgba(0,0,0,0.2);
  ${({ standalone }) => standalone ? 'box-shadow: none;' : ''}
`

const Filters = styled.div`
  & > *:not(:first-child) {
    margin-left: 2rem;
  }

  ${({ enableSelectAll }) => enableSelectAll && `
    & > *:nth-child(2) {
      &::before {
        content: '';
        border-right: 1px solid #E5E5E5;
        margin-right: 2rem;
      }
    }
  `}
`

const StyledContainer = styled(Container)`
  ${({ standalone }) => standalone ? 'width: 100% !important;' : ''}
`

const AllCamerasContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  rows: ${({ rowNb }) => `repeat(${rowNb}, 1fr)`};
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.5rem;
`

const Check = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 100%;
  border: 2px solid #fff;
  display: flex;
  transition: transform .2s ease-out, opacity .2s ease-out;
`

const CameraContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  font-family: Blinker;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
  text-align: center;
  border: 2px solid ${({ theme, selected }) => selected ? theme.primaryColor : '#999999'};
  color: ${({ selected }) => selected ? '#fff' : '#999999'};
  background-color: ${({ theme, selected }) => selected ? theme.primaryColor : 'transparent'};
  border-radius: 5px;
  padding: .2rem 0;

  ${Check} {
    transform: ${({ selected }) => selected ? 'scale(1)' : 'scale(1.5)'};
    opacity: ${({ selected }) => selected ? '1' : '0'};
  }
`

const SearchCamerasSection = ({
  // "Standard" cameras' list props
  camerasList,
  toggle, // can show/hide component with animation
  sectors,
  selectedCameras,
  // "Advanced" cameras' list props
  setSelectedCameras,
  enableSelectAll, // display checkbox to select all cameras
  standalone, // no container
  tooltip, // tooltip when hover camera
  deleteCamera,
  modifyCamera,
  loading
}) => {
  const [selectedSectorsCamera, setSelectedSectorsCamera] = useState([])
  const rowNb = camerasList.length / 9

  const handleZoneChange = (sectorName) => {
    const isSelected = selectedSectorsCamera && selectedSectorsCamera.find(sector => sector === sectorName)
    let newSelectedSectorsCamera = [...selectedSectorsCamera]

    if (!isSelected) {
      newSelectedSectorsCamera = [
        ...selectedSectorsCamera,
        sectorName,
      ]
    } else {
      newSelectedSectorsCamera = newSelectedSectorsCamera.filter(sector => sector !== sectorName)

      const sectorInfo = sectors.find(sector => sector.name === sectorName)
      const cameras = sectorInfo?.cameras && sectorInfo.cameras.split(',')
      const newSelectedCameras = selectedCameras.filter(camera => (
        cameras ? !cameras.includes(String(camera)) : true
      ))

      setSelectedCameras(newSelectedCameras)
    }

    newSelectedSectorsCamera.map(s => {
      const sectorInfo = sectors.find(sector => sector.name === s)
      const cameras = sectorInfo?.cameras && sectorInfo.cameras.split(',')

      if (cameras && !isSelected) {
        const newSelectedCameras = [...selectedCameras, ...cameras.map(camera => Number(camera))]
        const noDuplicates = [...new Set(newSelectedCameras)]

        setSelectedCameras(noDuplicates)
      }
    })

    setSelectedSectorsCamera(newSelectedSectorsCamera)
  }

  const toggleCamera = id => {
    const _cameras = selectedCameras
    const _i = _cameras.indexOf(id)

    setSelectedCameras(
      _i === -1
        ? [..._cameras, id]
        : _cameras.filter(_id => _id !== id)
    )
  }

  const toggleAllCameras = value => {
    setSelectedCameras(value ? camerasList.map(({ id }) => id) : [])
  }

  return (
    <SearchCamerasSectionContainer standalone={standalone} toggle={toggle}>
      <StyledContainer standalone={standalone}>
        <Filters enableSelectAll={enableSelectAll}>
          {
            enableSelectAll && (
              <Checkbox
                bold
                inline
                checked={selectedCameras?.length === camerasList?.length}
                onChange={v => toggleAllCameras(v)}
                label='Périmètre entier'
              />
            )
          }
          {
            sectors && sectors.length !== 0 &&
            sectors.map((sector, i) =>
            sector?.cameras ? (
              <Checkbox
                key={`${sector.name}-${i}`}
                onChange={() => handleZoneChange(sector.name)}
                label={sector.name}
                inline
              />
            ) : null)
          }
        </Filters>

          {
            camerasList.length === 0 &&
            <CenterContainer>Aucunes caméras trouvées.</CenterContainer>
          }
          {
            camerasList.length !== 0 &&
            <AllCamerasContainer rowNb={rowNb}>
            {
              loading ? (
                <SpinnerAnimatedIcon />
              ) : (
                camerasList.map(
                  (camera, i) => tooltip ? (
                    <CameraTooltip
                      camera={camera}
                      deleteCamera={deleteCamera}
                      modifyCamera={modifyCamera}
                      key={`${camera.id}-${i}`}
                    >
                      <CameraContainer
                        onClick={() => toggleCamera(camera.id)}
                        selected={selectedCameras.find(id => id === camera.id)}
                        key={`${camera.id}-${i}`}
                      >
                        <Check>
                          <CheckIcon />
                        </Check>
                        {camera.name}
                      </CameraContainer>
                    </CameraTooltip>
                  ) : (
                    <CameraContainer
                      onClick={() => toggleCamera(camera.id)}
                      selected={selectedCameras.find(id => id === camera.id)}
                      key={`${camera.id}-${i}`}
                    >
                      <Check>
                        <CheckIcon />
                      </Check>
                      {camera.name}
                    </CameraContainer>
                  )
                )
              )
            }
            </AllCamerasContainer>
          }
      </StyledContainer>
    </SearchCamerasSectionContainer>
  )
}

SearchCamerasSection.propTypes = {
  // "Standard" cameras' list props
  camerasList: PropTypes.array,
  toggle: PropTypes.bool,
  toggleAllCameras: PropTypes.func,
  selectedCameras: PropTypes.array,
  setSelectedCameras: PropTypes.func,
  sectors: PropTypes.array,
  limit: PropTypes.number,
  // "Advanced" cameras' list props
  standalone: PropTypes.bool,
  tooltip: PropTypes.bool,
  enableSelectAll: PropTypes.bool,
  deleteCamera: PropTypes.func,
  modifyCamera: PropTypes.func,
}

SearchCamerasSection.defaultProps = {
  // "Standard" cameras' list props
  camerasList: [],
  toggleAllCameras: () => {},
  sectors: [],
  selectedCameras: [],
  // "Advanced" cameras' list props
  setSelectedCameras: () => {},
  standalone: false,
  toggle: true,
  tooltip: false,
  enableSelectAll: true,
  deleteCamera: () => {},
  modifyCamera: () => {},
}

export default SearchCamerasSection
