import 'whatwg-fetch'
import React, { Component } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { H1, H3 } from '../components/Typography'
import { Button } from '../components/Button'

const Input = styled.input``

const InputFile = styled.input`
  width: 90%;
  border: 1px dashed #a4a6aa;
  margin: 20px 0;
  padding: 20px 5% 10px;
`

const ButtonUpload = styled(Button)`
  width: 100%;
`

const UploadIcon = styled(FontAwesomeIcon)`
  display: block;
  margin: 30px auto;
  font-size: 3rem;
  color: #74ccf8;
`

const ImportGenetechContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 60px;
`

const ImportMilestoneContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 60px;
`

const UploadZoneContainer = styled.div`
  padding: 20px 0 60px;
`

const UploadZoneForm = styled.div``

const UploadZoneFiles = props => (
  <ButtonUpload
    type="button"
    onClick={props.onUploadFiles}
  >
    Upload
  </ButtonUpload>
)

class Videos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFile: null,
      onLoad: false,
      onSuccess: false
    }
  }

  onChangeFiles(event) {
    if (!this.state.onLoad) {
      this.setState({
        selectedFile: event.target.files[0],
        onLoad: false,
        onSuccess: false
      })
    }
  }

  onUploadFiles() {
    this.setState({
      ...this.state,
      onLoad: true
    }, () => {
      const data = new FormData()

      data.append('file', this.state.selectedFile)

      fetch('/api/uploads', {
        method: 'POST',
        body: data
      }).then(() => {
        this.setState({
          selectedFile: null,
          onLoad: false,
          onSuccess: true
        })
      })
    })
  }

  render() {
    let uploadZone = <UploadIcon icon={faCircleNotch} spin />;

    if (!this.state.onLoad) {
      uploadZone = (
        <UploadZoneContainer>
          <InputFile
            type="file"
            name="file"
            onChange={this.onChangeFiles.bind(this)}
          />
          <UploadZoneForm>
            <label htmlFor="filename">Titre de la séquence: </label>
            <Input type="text" name="filename" />
            <label htmlFor="filename">Description: </label>
            <Input type="text" name="filename" />
            <label htmlFor="filename">Date: </label>
            <label htmlFor="filename">Lieu: </label>
            <UploadZoneFiles onUploadFile={this.onUploadFiles.bind(this)} />
          </UploadZoneForm>
        </UploadZoneContainer>
      )
    }

    return (
      <div>
        <H1>Importer</H1>
        <H3>Ajouter des fichiers vidéos à analyser (.mp4, .avi etc...)</H3>
        {uploadZone}
        <H3>Ajouter des fichiers vidéos à analyser depuis Genetech</H3>
        <ImportGenetechContainer>
          <Button>Lancer l'import depuis Genetech</Button>
        </ImportGenetechContainer>
        <H3>Ajouter des fichiers vidéos à analyser depuis Milestone</H3>
        <ImportMilestoneContainer>
          <Button>Lancer l'import depuis Milestone</Button>
        </ImportMilestoneContainer>
      </div>
    )
  }
}

export default Videos
