import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { H1, H3, P } from '../components/Typography'
import { TextInput, EditIcon, Container, FormGroup, Checkbox, CheckIcon } from '../components/design-system'
import { BigButton } from '../components/Button'
import { Select } from '../components/Input'
import AuthContext from '../contexts/AuthContext'

import * as services from '../services'

const StyledH1 = styled(H1)`
  font-family: Blinker;
  margin-top: .5rem;
  margin-bottom: 2.5rem;
`

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.8125rem;

  ${FormGroup} {
    margin-bottom: 1.5625rem;
    width: 23.75rem;
  }

  ${P} {
    text-align: center;
  }
`

const NotificationContainer = styled(Content)`
  margin-top: 1rem ;
`

const StyledH3 = styled(H3)`
  display: flex;
  align-items: center;
`

const Seperator = styled.div`
  margin: 2rem 0;
  height: 1px;
  width: 100%;
  background-color: #E5E5E5;
`

const StyledCheckbox = styled(Checkbox)`
  label {margin-bottom: 1rem;}
`

const StyledBigButton = styled(BigButton)`
  width: auto;
`

export default () => {
  const [{ user }] = useContext(AuthContext)
  const [userInfo, setUserInfo] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    company: user.company,
    employeeId: user.employeeId,
  })
  const [notificationInfo, setNotificationInfo] = useState({
    server: '',
    email: '',
    password: '',
    port: '',
    protocol: 'SSL',
  })
  const [emailNotifications, setEmailNotifications] = useState(true)
  const [smsNotifications, setSmsNotifications] = useState(true)
  const [phone, setPhone] = useState('')

  const handleSubmit = () => {
    let params = { ...userInfo }

    if (emailNotifications) {
      params = {
        ...params,
        emailNotifications,
        server: notificationInfo.server,
        email_notification: notificationInfo.email,
        password_notification: notificationInfo.password,
        port: Number(notificationInfo.port),
        protocol: notificationInfo.protocol,
      }
    }
    if (smsNotifications) {
      params = {
        ...params,
        smsNotifications,
        phone: phone.split(' ').join(''),
      }
    }

    console.log(params)

    services.updateMe(params)
    .then(({ }) => {
    })
  }

  const handleNotif = (value, type) => {
    const types = {
      email: setEmailNotifications,
      sms: setSmsNotifications,
    }

    types[type](value)
  }

  return (
    <>
      <StyledH1>{`${user.firstname} ${user.lastname}`}</StyledH1>
      <Content>
        <FormGroup>
          <TextInput
            buttonIcon={EditIcon}
            value={userInfo.firstname}
            onChange={v => setUserInfo({...userInfo, firstname: v})}
            label='Prénom'
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            buttonIcon={EditIcon}
            value={userInfo.lastname}
            onChange={v => setUserInfo({...userInfo, lastname: v})}
            label='Nom'
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            buttonIcon={EditIcon}
            value={userInfo.email}
            onChange={v => setUserInfo({...userInfo, email: v})}
            label='Email'
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            buttonIcon={EditIcon}
            value={userInfo.company}
            onChange={v => setUserInfo({ ...userInfo, company: v})}
            label='Entreprise'
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            buttonIcon={EditIcon}
            value={userInfo.employeeId}
            onChange={v => setUserInfo({ ...userInfo, employeeId: v})}
            label='N° de poste'
          />
        </FormGroup>
        <Seperator />
        <StyledH3>Notifications</StyledH3>
        <P>
          Afin de vous alerter et de partager des résultats de recherche par email
          avec d’autres utilisateurs, merci de renseigner les champs ci-dessous.
        </P>
        <P>
          Veuillez noter que les données renseignées vous concernant serviront
          uniquement dans le cadre du bon fonctionnement de ce logiciel et
          ne seront en aucun cas exploitées pour quelqu’usage commercial que ce soit.
        </P>
        <StyledCheckbox
          inline
          checked={emailNotifications}
          onChange={v => handleNotif(v, 'email')}
          label='Notifications/partage par email'
        />
        {
          emailNotifications &&
          <NotificationContainer>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={notificationInfo.server}
                onChange={v => setNotificationInfo({ ...notificationInfo, server: v })}
                label='Serveur SMTP'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={notificationInfo.email}
                onChange={v => setNotificationInfo({ ...notificationInfo, email: v })}
                label='Email'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={notificationInfo.password}
                onChange={v => setNotificationInfo({ ...notificationInfo, password: v })}
                label='Password'
                password
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={notificationInfo.port}
                onChange={v => setNotificationInfo({ ...notificationInfo, port: v })}
                label='Port'
              />
            </FormGroup>
            <FormGroup>
              <Select
                onChange={v => setNotificationInfo({ ...notificationInfo, protocol: v })}
                value={notificationInfo.protocol}
              >
                <option value="groupBy:desc">SSL</option>
              </Select>
            </FormGroup>

          </NotificationContainer>
        }
          <StyledCheckbox
            inline
            checked={smsNotifications}
            onChange={v => handleNotif(v, 'sms')}
            label='Notifications par SMS'
          />
          {
            smsNotifications &&
            <NotificationContainer>
              <FormGroup>
                <TextInput
                  buttonIcon={EditIcon}
                  value={phone}
                  onChange={v => setPhone(v)}
                  label='Numéro de téléphone'
                />
              </FormGroup>
            </NotificationContainer>
          }
      </Content>
      <StyledBigButton
        type='submit'
        leftIcon={<CheckIcon />}
        onClick={handleSubmit}
      >
        Valider les changements
      </StyledBigButton>
    </>
  )
}
